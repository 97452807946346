import i18n from '@/i18n'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'Detachments'
const apiPath = '/detachments-offline-online/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state,
  detachmentsOptions: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getDetachmentOfflineOnline ({ commit }, pk) {
    const alertMessage = {}
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error.response.data)
        if (error.response.status === 403) {
          alertMessage['level'] = RESPONSE_LEVEL.WARNING
          alertMessage['message'] = error.response.data.detail
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        }
        return alertMessage
      })
  },
  createDetachmentOfflineOnline ({ commit, state }) {
    const alertMessage = {}
    return apiClient
      .post(`${apiPath}`, state.currentItem)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error.response.data)
        if (error.response.status === 403) {
          alertMessage['level'] = RESPONSE_LEVEL.WARNING
          alertMessage['message'] = error.response.data.detail
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        }
        return alertMessage
      })
  },
  getDetachmentOfflineOnlineOption ({ commit }) {
    return apiClient
      .get(`${apiPath}options/`)
      .then(response => {
        commit('setDetachmentOfflineOnlineOption', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setDetachmentOfflineOnlineOption  (state, payload) {
    state.detachmentsOptions = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
