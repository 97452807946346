const moduleName = 'titan'
const modulePath = `/${moduleName}`

/* Note on dynamic import :

  We use them to only load the components when the route is visited.

  For now webpack has some limitations and we CANT put much of the import path in a variable
  so we have to repeat the ../../modules ....
 */

export default [
  {
    path: modulePath + '/catalogs/product-brands',
    name: 'ProductBrandList',
    component: () => import('../../apps/' + moduleName + '/views/ProductBrandList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/product-brands/:id',
    name: 'ProductBrandDetail',
    component: () => import('../../apps/' + moduleName + '/views/ProductBrandDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/product-category',
    name: 'ProductCategoryList',
    component: () => import('../../apps/' + moduleName + '/views/ProductCategoryList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/product-category/:id',
    name: 'ProductCategoryDetail',
    component: () => import('../../apps/' + moduleName + '/views/ProductCategoryDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/clients',
    name: 'GroupsList',
    component: () => import('../../apps/' + moduleName + '/views/GroupsList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/chains',
    name: 'ChainsList',
    component: () => import('../../apps/' + moduleName + '/views/ChainsList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/products',
    name: 'ProductList',
    component: () => import('../../apps/' + moduleName + '/views/ProductList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/products/:id',
    name: 'ProductDetail',
    component: () => import('../../apps/' + moduleName + '/views/ProductDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/client/:id',
    name: 'GroupsDetail',
    component: () => import('../../apps/' + moduleName + '/views/GroupsDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/chains/:id',
    name: 'ChainsDetail',
    component: () => import('../../apps/' + moduleName + '/views/ChainsDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/chains/new',
    name: 'ChainsCreate',
    component: () => import('../../apps/' + moduleName + '/views/ChainsDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/downloadListView',
    name: 'PendingDownloadList',
    component: () => import('../../apps/' + moduleName + '/views/PendingDownloadListView/PendingDownloadListView.vue'),

    meta: {
      requiresAuth: true
    }
  }
]
