import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '../../core/store/genericApiViewStore'

const modelName = 'ProductOrder'
const apiPath = '/product-orders/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
