import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'AgreementLine'
const apiPath = '/agreement_lines/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  getActionPath: '/actions_agreement_line/'
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  downloadAgreementLinesCsvFile ({ dispatch }, items) {
    return dispatch('downloadAjaxFile', {
      apiPath: '/download/agreementlines/excel/',
      data: { [modelName]: items },
      filenameHeader: 'x-filename'
    })
  },
  addLinesByFilter ({ _ }, params) {
    console.log('addLinesByFilter', params)
    return apiClient.post('/agreement_lines/filters/', {
      [modelName]: params
    }).then(response => {
      console.log(response)
    })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
