import moment from 'moment'

export function dateToDateRangeString (date) {
  if (date === undefined || date === null) return

  var dateLower = date
  var dateUpper = moment(dateLower).add(7, 'days').format('YYYY-MM-DD')

  date = { 'bounds': '[)', 'lower': dateLower, 'upper': dateUpper }
  return JSON.stringify(date)
}

export function dateToDateRange (lower = null, upper = null, bounds = null) {
  const dateLower = lower ? moment(lower).format('YYYY-MM-DD') : ''
  let dateUpper = upper ? moment(upper).format('YYYY-MM-DD') : ''

  if (bounds === '[)' && dateUpper !== '') {
    dateUpper = moment(upper).subtract(1, 'days').format('YYYY-MM-DD')
  }

  const date = { 'bounds': '[)', 'lower': dateLower, 'upper': dateUpper }
  return JSON.stringify(date)
}

export function detectAndFormatTypes (value) {
  if (typeof (value) === 'string') {
    return isDateAndTimeDetected(value) ? formatDateAndTime(value)
      : isDateDetected(value) ? formatDate(value)
        : detectAndFormatOtherTypes(value) || value
  }
  return value
}

export function detectAndFormatOtherTypes (value) {
  /** Detect number */
  return !isNaN(Number(value)) ? Number(value)
    /** Detect boolean */
    : ['true', 'false'].includes(value) ? (value === 'true')
      : value
}

export function detectAndFormatDate (value) {
  if (typeof (value) === 'string') {
    return isDateAndTimeDetected(value) ? formatDateAndTime(value)
      : isDateDetected(value) ? formatDate(value)
        : value
  }
  return value
}

export function isDateAndTimeDetected (string) {
  return !!string.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/g)
}

export function isDateDetected (string) {
  return !!(string.match(/[0-9]{4}-[0-9]{2}-[0-9]{2}/g))
}

export function formatDateAndTime (date) {
  const outputDate = new Date(date)
  let day = outputDate.getDate()
  day = day < 10 ? '0' + day.toString() : day.toString()
  let month = outputDate.getMonth() + 1
  month = month < 10 ? '0' + month.toString() : month.toString()
  const year = outputDate.getFullYear()
  let hours = outputDate.getHours()
  hours = hours < 10 ? '0' + hours.toString() : hours.toString()
  let minutes = outputDate.getMinutes()
  minutes = minutes < 10 ? '0' + minutes.toString() : minutes.toString()
  let seconds = outputDate.getSeconds()
  seconds = seconds < 10 ? '0' + seconds.toString() : seconds.toString()
  return day + '/' + month + '/' + year + ' a las ' + hours + ':' + minutes + ':' + seconds
}

export function formatDate (date) {
  const outputDate = new Date(date)
  let day = outputDate.getDate()
  day = day < 10 ? '0' + day.toString() : day.toString()
  let month = outputDate.getMonth() + 1
  month = month < 10 ? '0' + month.toString() : month.toString()
  const year = outputDate.getFullYear()
  return day + '/' + month + '/' + year
}

/**
 * Returns a capitalized String
 * @param string
 */
export function capitalize (string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Used to parse "${params}" from raw JSON url strings (i.e. API routes)
 * @param string
 */
export function parseFormattedUrl (rawUrlString, params) {
  let formattedUrl = `/`
  let pathsArray = rawUrlString.split('/')
  pathsArray.forEach(path => {
    if (path) {
      // eslint-disable-next-line no-useless-escape
      let formattedPath = path.match(/[^${\}]+(?=})/g)
      formattedUrl = formattedUrl.concat(formattedPath ? `${params[formattedPath]}/` : `${path}/`)
    }
  })
  return formattedUrl
}

/**
 * Merges "prop__" attributes from form raw object into a nested props object.
 * @param instance {Object} raw object from CustomBlockForm (Or others)
 * @returns {Object} object with 'prop__' attributes "moved" to the props object
 */
export function parseFormProps (instance) {
  let parsedInstance = { props: {} }
  Object.keys(instance).forEach(key => {
    if (key.startsWith('prop__')) {
      let propKey = key.split('prop__')[1]
      parsedInstance.props[propKey] = instance[key]
    } else {
      parsedInstance[key] = instance[key]
    }
  })
  return parsedInstance
}

/**
 * Changes "prop__" attributes from form raw object into "props__" attributes.
 * @param instance {Object} raw object from CustomBlockForm (Or others)
 * @returns {Object} object where 'prop__' attributes are changed into 'props__'
 */
export function propToProps (instance) {
  let parsedInstance = {}
  Object.keys(instance).forEach(key => {
    if (key.startsWith('prop__')) {
      let propKey = key.replace('prop__', 'props__')
      parsedInstance[propKey] = instance[key]
    } else {
      parsedInstance[key] = instance[key]
    }
  })
  return parsedInstance
}

/**
 * Flattens props object from API into 'prop__' attributes to be used in forms
 * @param instance {Object} raw object from API (Or others) with props object
 * @returns {Object} object with props object flattened to 'prop__' attributes
 */
export function flattenFormProps (instance) {
  let flattenedInstance = {}
  Object.assign(flattenedInstance, instance)
  if (instance.props) {
    flattenedInstance.props = {}
    Object.keys(instance.props).forEach(prop => {
      flattenedInstance[`prop__${prop}`] = instance.props[prop]
    })
  } else {
    Object.keys(instance).forEach(key => {
      if (key.startsWith('props.')) {
        console.log('flatten')
        let propKey = key.replace('props.', 'prop__')
        delete flattenedInstance[key]
        flattenedInstance[propKey] = instance[key]
      }
    })
  }
  return flattenedInstance
}

/**
 * Returns instance without 'prop__' attributes (These shall already exist at the 'props' object)
 * @param instance
 * @returns {{}}
 */
export function removeFlatProps (instance) {
  let modifiedInstance = Object.assign({}, instance)
  Object.keys(modifiedInstance).forEach((key) => {
    if (key.startsWith('prop__')) {
      delete modifiedInstance[key]
    }
  })
  return modifiedInstance
}
