// This file could be used to define static variables to be used anywhere in the frontend

const httpPrefix = process.env.VUE_APP_SSL_ACTIVE === 'true' ? 'https' : 'http'
const apiPort = process.env.VUE_APP_SSL_ACTIVE === 'true' ? process.env.VUE_APP_NGINX_EXTERNAL_SSL_PORT : process.env.VUE_APP_NGINX_EXTERNAL_PORT
// const apiPort = 16010
const isLocalhost = window.origin.includes('localhost') || window.origin.includes(process.env.VUE_APP_LOCALHOST_ENDPOINT)
const BASE_LOCALHOST_ENDPOINT = `${httpPrefix}://${process.env.VUE_APP_LOCALHOST_ENDPOINT}${(':' + apiPort) || ''}`

export const BASE_URL = (isLocalhost && process.env.VUE_APP_LOCALHOST_ENDPOINT) ? BASE_LOCALHOST_ENDPOINT : window.origin

export const API_URL = `${BASE_URL}/${process.env.VUE_APP_API}`
export const LOGIN_URL = `${BASE_URL}/${process.env.VUE_APP_LOGIN}/`
export const LOGIN_REDIRECT_URL = `${process.env.VUE_APP_LOGIN_REDIRECT_URL}`
export const RESET_PASSWORD_URL = `${BASE_URL}/${process.env.VUE_APP_RESET_PASSWORD}/`
export const USE_MOCK = false
export const APPLICATION_LOGO = `${BASE_URL}/media/cosmos_options/logo/logo.png`
export const ENVIRONMENT = process.env.VUE_APP_CURRENT_CLIENT
export const SUPPORT_URL = process.env.VUE_APP_SUPPORT_URL
export const AUTH0_DOMAIN = process.env.VUE_APP_AUTH0_DOMAIN
export const AUTH0_CLIENT_ID = process.env.VUE_APP_AUTH0_CLIENT_ID
export const AUTH0_ENABLED = process.env.VUE_APP_AUTH0_ENABLED === 'true'
export const LAST_VERSION = process.env.VUE_APP_LAST_VERSION

// cosmos only vars
export const DISTRIBUTOR_GROUP_LEVEL = 3

export const RESPONSE_LEVEL = {
  SUCCESS: 'success',
  WARNING: 'warning',
  ERROR: 'error',
  INFO: 'info'
}

// Type of user. This will be removed when all the information is brought back.
export const IS_SCH = ENVIRONMENT === 'SCH'
export const IS_LOR = ENVIRONMENT === 'LOR'
export const IS_CAC = ENVIRONMENT === 'CAC'
export const IS_PNG = ENVIRONMENT === 'PNG'
export const IS_MET = ENVIRONMENT === 'MET'

// Magic id used in multiple update views.
export const MULTIPLE_UPDATE_ID = -30

// Select all id used in table actions
export const SELECT_ALL_ID = -1

export const APP_LANGUAGES = ['es', 'en']

// Configuration to load locales files. "required" key must be false if files can be not found in that directory.
export const LOCALES_CONFIG = [
  { dir: 'apps/core/locales', required: true },
  { dir: `apps/core/locales/${ENVIRONMENT}`, required: false },
  { dir: 'lib/uncrudtable/locales', required: true }
]
