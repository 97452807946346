import i18n from '@/i18n'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'Promo'
const apiPath = '/promos/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getPromo ({ commit, state }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        let promo = response.data
        commit('setCurrentItem', promo)
        return promo
      })
      .catch((error) => {
        console.error(error)
      })
  },
  newPromo ({ commit }, pk) {
    const alertMessage = {}
    return apiClient.post(apiPath, { campaign: pk })
      .then(response => {
        alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
        alertMessage['id'] = response.data.id
        return alertMessage
      })
      .catch(error => {
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
        }
        return alertMessage
      })
  },
  newPromoFromTemplate ({ commit }, { pk, template }) {
    const alertMessage = {}
    return apiClient.post(apiPath, { ...template, campaign: pk })
      .then(response => {
        alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
        alertMessage['id'] = response.data.id
        return alertMessage
      })
      .catch(error => {
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
        }
        return alertMessage
      })
  },
  cloneItem ({ commit }, pk) {
    return apiClient
      .post(apiPath + 'clone/' + pk)
      .then(response => {
        const { errors } = response.data
        if (errors) {
          console.error('error cloning ' + modelName)
          throw response
        }
        return response
      })
  },
  getPromoGroupDetail ({ state }) {
    return apiClient
      .get(`/promo_group_detail/${state.currentItem.promo_group}/`)
      .then(response => {
        return response.data
      })
  }
}
const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
