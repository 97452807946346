import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'ProductBrand'
const apiPath = '/product_brands/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  productCategories: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getProductCategories ({ commit }) {
    return apiClient.get('/product_categories/').then(response => {
      const results = response.data
      if (results['results']) {
        commit('setProductCategories', {
          productCategories: results['results']
        })
      } else {
        console.error('No results for distributor types')
      }
    })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setProductCategories (state, payload) {
    state.productCategories = payload.productCategories
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
