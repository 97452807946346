const moduleName = 'dido'
const modulePath = `/${moduleName}`

/* Note on dynamic import :

  We use them to only load the components when the route is visited.

  For now webpack has some limitations and we CANT put much of the import path in a variable
  so we have to repeat the ../../modules ....
 */

export default [
  {
    path: modulePath + '/campaign',
    name: 'CampaignList',
    component: () => import('../../apps/' + moduleName + '/views/CampaignList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaigns/:id',
    name: 'CampaignDetail',
    component: () => import('../../apps/' + moduleName + '/views/CampaignDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-massive-creation',
    name: 'CampaignMassiveCreation',
    component: () => import('../../apps/' + moduleName + '/views/CampaignMassiveCreation/CampaignMassiveCreation.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/notes',
    name: 'NotesList',
    component: () => import('../../apps/' + moduleName + '/views/NotesListView/NotesListView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/notes/:id',
    name: 'NoteDetailView',
    component: () => import('../../apps/' + moduleName + '/views/NoteDetailView/NoteDetailView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/promo-note-validations',
    name: 'PromoNoteValidationList',
    component: () => import('../../apps/' + moduleName + '/views/PromoNoteValidationList/PromoNoteValidationList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/promo-detachments-assignation',
    name: 'PromoDetachmentsAssignationList',
    component: () => import('../../apps/' + moduleName + '/views/PromoDetachmentsAssignationList/PromoDetachmentsAssignationList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/allocate-promo-detachments',
    name: 'PromoDetachmentsToAllocate',
    component: () => import('../../apps/' + moduleName + '/views/PromoDetachmentsToAllocate/PromoDetachmentsToAllocate.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/notes/promo-detachment-validations',
    name: 'PromoKbdValidationList',
    component: () => import('../../apps/' + moduleName + '/views/PromoKbdValidationList/PromoKbdValidationList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-templates',
    name: 'CampaignTemplateList',
    component: () => import('@/apps/' + moduleName + '/views/CampaignTemplateList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-templates/:id',
    name: 'CampaignTemplateDetail',
    component: () => import('@/apps/' + moduleName + '/views/CampaignTemplateDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/promo-templates/:id',
    name: 'PromoTemplateDetail',
    component: () => import('@/apps/' + moduleName + '/views/PromoTemplateDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-multiple-update',
    name: 'UpdateMultipleCampaign',
    component: () => import('../../apps/' + moduleName + '/views/UpdateMultipleCampaign.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/promos/:id',
    name: 'PromoDetail',
    component: () => import('../../apps/' + moduleName + '/views/PromoDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/topics',
    name: 'TopicList',
    component: () => import('../../apps/' + moduleName + '/views/TopicList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/topics/:id',
    name: 'TopicDetail',
    component: () => import('../../apps/' + moduleName + '/views/TopicDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/detachments-offline-online',
    name: 'DetachmentsOfflineOnlineList',
    component: () => import('../../apps/' + moduleName + '/views/DetachmentsOfflineOnlineList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/catalogs/detachments-offline-online/:id',
    name: 'DetachmentsOfflineOnlineDetail',
    component: () => import('../../apps/' + moduleName + '/views/DetachmentsOfflineOnlineDetail.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-clients-calendar',
    name: 'CampaignClientsCalendar',
    component: () => import('../../apps/' + moduleName + '/views/BaseCalendarView/BaseCalendarView.vue'),
    props: {
      storeModule: 'campaignClientsCalendar',
      layoutTitle: 'calendars.campaign_clients',
      viewFilterName: 'clients_calendar',
      redirectPromo: 'PromoDetail',
      redirectCampaign: 'CampaignDetail'
    },

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-products-calendar',
    name: 'CampaignProductsCalendar',
    component: () => import('../../apps/' + moduleName + '/views/BaseCalendarView/BaseCalendarView.vue'),
    props: {
      storeModule: 'campaignProductsCalendar',
      layoutTitle: 'calendars.campaign_products',
      viewFilterName: 'products_calendar',
      redirectPromo: 'PromoDetail',
      redirectCampaign: 'CampaignDetail'
    },

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-template-clients-calendar',
    name: 'CampaignTemplateClientsCalendar',
    component: () => import('../../apps/' + moduleName + '/views/BaseCalendarView/BaseCalendarView.vue'),
    props: {
      storeModule: 'campaignTemplateClientsCalendar',
      layoutTitle: 'calendars.campaign_template_clients',
      viewFilterName: 'clients_template_calendar',
      redirectPromo: 'PromoTemplateDetail',
      redirectCampaign: 'CampaignTemplateDetail'
    },

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/campaign-template-products-calendar',
    name: 'CampaignTemplateProductsCalendar',
    component: () => import('../../apps/' + moduleName + '/views/BaseCalendarView/BaseCalendarView.vue'),
    props: {
      storeModule: 'campaignTemplateProductsCalendar',
      layoutTitle: 'calendars.campaign_template_products',
      viewFilterName: 'products_template_calendar',
      redirectPromo: 'PromoTemplateDetail',
      redirectCampaign: 'CampaignTemplateDetail'
    },

    meta: {
      requiresAuth: true
    }
  }
]
