export default {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#62C4D8',
        secondary: '#FDEB44',
        anchor: '#7D3AA0',
        accent: '#62C4D8'
      },
      dark: {}
    }
  },
  icons: {
    iconfont: 'fa'
  }
}
