import cloneDeep from 'lodash/cloneDeep'
// import agreements from './agreements'
// import agreement_lines from './agreement_lines'
// import agreement_detail from './agreement_detail'
// import distributors from './distributors'
// import distributor_levels from './distributor_levels'
// import settlement_types from './settlement_types'
// import users from './users'
// import rates from './rates'
// import locations from './locations'
//
//
// const mockData = {
//     '/agreements/': agreements,
//     '/agreement_lines/': agreement_lines,
//     '/agreement_detail/': agreement_detail,
//     '/distributor_levels/': distributor_levels,
//     '/distributors/': distributors,
//     '/settlement_types/': settlement_types,
//     '/users/': users,
//     '/locations/': locations,
//     '/rates/': rates,
// }
//

const mockData = {
}

const fetch = (mockData, time = 0) => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ data: mockData })
    }, time)
  })
}

export default {
  login (payload) {
    localStorage.setItem('token', 'fake')
    localStorage.setItem('user', 'mock')
    console.log(`Mock Loggin RQ with: ${payload}`)
    return fetch({ token: 'mock' })
  },

  resetPassword (payload) {
    localStorage.setItem('token', 'fake')
    localStorage.setItem('user', 'mock')
    console.log(`Mock resetPassword RQ with: ${payload}`)
    return fetch({ })
  },

  get (apiPath, params) {
    console.log(`mock get to ${apiPath} with ${JSON.stringify(params)}`)
    let response = {
      results: [],
      count: 0,
      offset: 0,
      next: null,
      previous: null,
      data: 1
    }
    if (apiPath in mockData) {
      response = cloneDeep(mockData[apiPath])
      if (params && 'external_id' in params) {
        response.results = response.results.filter(
          x => x.external_id === params.external_id
        )
      }
    } else {
      console.log('not mock data found, returning empty response')
    }

    return fetch(response)
  },

  post (apiPath, params) {
    const response = { errors: 0, created: 1 }
    console.info('mock post', apiPath, params)
    return fetch(response)
  },

  delete (apiPath, params) {
    const response = { errors: 0 }
    console.info('mock delete', apiPath, params)
    return fetch(response)
  }

}
