import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'PendingDownload'
const apiPath = '/pending-downloads/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  statusList: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
