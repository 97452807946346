import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'

const modelName = 'ImportTemplate'
const apiPath = '/campaign-massive-creation/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  ...genericStore.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,
  createCampaigns: async ({ dispatch }, campaignsToCreate) => {
    const promises = campaignsToCreate.map(campaignFile => {
      const formData = new FormData()
      formData.append('file', campaignFile, campaignFile.name)
      return apiClient.post(apiPath, formData)
    })
    const results = await Promise.allSettled(promises)
    const erroredFiles = results.reduce((acc, { status }, index) => {
      if (status === 'rejected') acc.push(campaignsToCreate[index].file.name)
      return acc
    }, [])
    return erroredFiles
  }
}

const mutations = {
  ...baseCrud.mutations,
  ...genericStore.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
