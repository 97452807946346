<i18n>
  {
  "en": {
  "nextLabel": "Next",
  "moreLabel": "more"
  },
  "es": {
  "nextLabel": "Siguiente",
  "moreLabel": "más"
  }
  }
</i18n>

<template>
  <v-snackbar v-if="!!currentNotification"
              :value=true
              :timeout=0
              :color=currentNotification.color
              :absolute=currentNotification.absolute
              :top=currentNotification.top
              :right=currentNotification.right
              :bottom=currentNotification.bottom
              :left=currentNotification.left
              :multi-line=currentNotification.multiLine
              :vertical=currentNotification.vertical
  >
    <template v-for="(message, index) in currentNotification.messages">
      {{ message }}<br :key="index" v-if="index !== currentNotification.messages.length - 1" >
    </template>
    <v-btn v-if="notificationsQueue.length > 1" text color="white" raised
           @click="removeNotification">
      {{$t('nextLabel')}} ({{notificationsQueue.length - 1}} {{$t('moreLabel')}})
    </v-btn>
    <v-btn v-else icon color="white" @click="removeNotification" raised>
      <v-icon dense small>fa-times</v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>


export default {
  name: 'un-notifications-queue',
  data() {
    return {
      currentNotification: null,
      currentTimeout: null,
      notificationsQueue: []
    };
  },
  computed: {
    storeNotifications() {
      return this.$store.state.unnotifications.notifications;
    }
  },
  methods: {
    removeNotification() {
      if (this.currentTimeout) clearTimeout(this.currentTimeout);
      this.currentTimeout = null;
      this.$store.dispatch('removeNotification');
      this.notificationsQueue.pop();
      if (this.currentNotification.onRemove) this.currentNotification.onRemove();
      this.currentNotification = null;
    }
  },
  watch: {
    storeNotifications(newStoreNotifications) {
      const currentNotifications = this.notificationsQueue.map(({ id }) => id);
      const newNotifications = newStoreNotifications.filter(({ id }) => !currentNotifications.includes(id));
      if (newNotifications.length > 0) this.notificationsQueue.push(...newNotifications);
    },
    notificationsQueue() {
      if (this.notificationsQueue.length > 0) {
        this.currentNotification = this.notificationsQueue[this.notificationsQueue.length -1];
        if (this.currentNotification.timeout) {
          this.currentTimeout = setTimeout(this.removeNotification, this.currentNotification.timeout);
        }
      }
    }
  }
};
</script>
