import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'AgreementClients'
const apiPath = '/agreements/clients/hierarchy/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

baseCrud.actions.getItemList = function ({ commit, state, rootState }, payload = {}) {
  commit('setLoading', true)
  const toSendParams = {
    limit: state.requestParams.limit,
    offset: state.requestParams.offset,
    agreement__external_id: rootState.agreements.currentItem.external_id,
    external_id: rootState.agreements.currentItem.distributor.external_id,
    ...payload
  }
  return apiClient
    .get(apiPath, toSendParams)
    .then(response => {
      commit('setItemList', { requestParams: toSendParams, response: response.data })
    })
    .finally(() => commit('setLoading', false))
}

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
