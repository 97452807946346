export default {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#0740a3',
        secondary: '#b3d4fc',
        anchor: '#1b0581',
        accent: '#1994d9'
      },
      dark: {}
    }
  },
  icons: {
    iconfont: 'fa'
  }
}
