
export function setup ({ axios, store, router }) {
  // 401  (auth error) Response interceptor
  axios.interceptors.response.use(undefined, function (err) {
    // eslint-disable-next-line no-unused-vars
    return new Promise(function (resolve, reject) {
      if (err.response && err.response.status === 401) {
        // if you ever get an unauthorized, logout the user
        store.dispatch('logout')
        // you can also redirect to /login if needed !
        router.push({ name: 'login', params: { authError: true } })
      }
      throw err
    })
  })
  // Request interceptor to add the Authorization on each RQ
  if (localStorage.getItem('token')) {
    axios.interceptors.request.use(function (config) {
      const token = localStorage.getItem('token')
      config.headers.Authorization = 'JWT ' + token
      return config
    })
  }
}
