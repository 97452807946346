import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const apiPath = '/internal_grouping/'

const state = {
  currentItem: {},
  newItem: {}
}
const getters = {
}
const actions = {
  getCampaignInternalGrouping ({ commit }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  createCampaignInternalGrouping ({ commit }, set) {
    return apiClient
      .post(apiPath, set)
      .then(response => {
        const responseMessage = {}
        responseMessage['status'] = response.data.alert_message.level
        responseMessage['messages'] = response.data.alert_message.message
        responseMessage['banner'] = response.data.banner
        return responseMessage
      })
      .catch(error => {
        const alertMessage = {}
        if (error.response.status === 403) {
          alertMessage['status'] = RESPONSE_LEVEL.WARNING
          alertMessage['messages'] = 'Lo siento, no tienes permisos para esta acción.'
        } else {
          alertMessage['status'] = RESPONSE_LEVEL.ERROR
          alertMessage['messages'] = 'Ha habido un error. Si persiste, póngase en contacto con el administrador del servicio.'
        }
        return alertMessage
      })
  },
  updateInternalGrouping ({ commit }, payload) {
    commit('setCurrentItem', payload)
  },
  updateNewItem ({ commit }, payload) {
    commit('setNewItem', payload)
  }
}
const mutations = {
  setCurrentItem (state, payload) {
    state.currentItem = payload
  },
  setNewItem (state, payload) {
    state.newItem = payload
  }
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
