import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { createNotification } from '@/lib/unnotificationsqueue'
import i18n from '@/i18n'

const modelName = 'Note'
const apiPath = '/note-promo-assignations/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state,
  noteLineStatus: [],
  noteLineOptions: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  async rejectNoteLine ({ commit, dispatch }, item) {
    commit('setLoading', true)
    try {
      const response = await apiClient.post(`/note-actions/`, {
        action_name: 'reject',
        list_ids: [item.id],
        extra_args: { 'allocated': item.calculated.remaining_value }
      })
      dispatch('addNotification', createNotification(response.data.message || 'Rejected 1 Payment Line', response.data.level || 'success'), { root: true })
      return true
    } catch (error) {
      return false
    } finally {
      commit('setLoading', false)
    }
  },

  createNoteLine ({ getters, commit, dispatch }, item) {
    commit('setLoading', true)
    const seletedPromoDetachment = getters.getDynamicDialogSelectedItems.find(
      promoDetachmentItem => promoDetachmentItem.parentId === item.id)
    return apiClient
      .post(`/note-lines/`, {
        note: item.id,
        promo_detachment: seletedPromoDetachment.id,
        status: item.note_line_status,
        allocated: item.calculated.remaining_value
      }).then((response) => {
        dispatch('addNotification', createNotification(i18n.t('dialogs.successCreation'), 'success'), { root: true })
        return true
      }).catch(() => {
        return false
      }).finally(() => {
        commit('setLoading', false)
      })
  },
  async createNoteLineCloseDetachment ({ getters, commit, dispatch }, item) {
    commit('setLoading', true)
    const seletedPromoDetachment = getters.getDynamicDialogSelectedItems.find(
      promoDetachmentItem => promoDetachmentItem.parentId === item.id)
    try {
      const response = await apiClient.post(`/note-actions/`, {
        action_name: 'create_approved_noteline_and_close_detachment',
        list_ids: [item.id],
        extra_args: { 'allocated': item.calculated.remaining_value, 'promo_detachment': seletedPromoDetachment.id }
      })
      dispatch('addNotification', createNotification(response.data.message, response.data.level), { root: true })
      return true
    } catch (error) {
      return false
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  ...baseCrud.mutations,
  cleanEditableValues (state, itemId) {
    state.items = state.items.map(item => {
      if (item.id === itemId) {
        item.note_line_status = null
        item.note_line_allocated = item.calculated.remaining_value
      }
      return item
    })
    state.dynamicDialogSelectedItems = state.dynamicDialogSelectedItems.filter(el => {
      return el.parentId !== itemId
    })
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
