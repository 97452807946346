import Login from './views/Login.vue'

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  }
]
