import axios from 'axios'
import { LOGIN_URL, API_URL, RESET_PASSWORD_URL } from '@/variables'

export default {
  login (payload) {
    return axios({ url: LOGIN_URL, data: payload, method: 'POST' })
      .then(resp => {
        const token = resp.data.token
        // const user = resp.data.user
        localStorage.setItem('token', token)
        localStorage.setItem('user', payload.username)
        axios.defaults.headers.common['Authorization'] = `JWT ${token}`
        return resp
      })
      .catch(err => {
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        throw err
      })
  },

  resetPassword (payload) {
    return axios({ url: RESET_PASSWORD_URL, data: payload, method: 'POST' })
      .then(resp => {
        return resp
      })
      .catch(err => {
        throw err
      })
  },

  get (apiPath, params) {
    var qs = require('qs')
    return axios
      .get(API_URL + apiPath, {
        params: params,
        paramsSerializer: function (params) {
          return qs.stringify(params, { arrayFormat: 'repeat' })
        }
      })
  },

  post (apiPath, data, confg) {
    return axios.post(API_URL + apiPath, data, confg)
  },

  patch (apiPath, data, confg) {
    return axios.patch(API_URL + apiPath, data, confg)
  },

  put (apiPath, data, confg) {
    return axios.put(API_URL + apiPath, data, confg)
  },

  delete (apiPath, data) {
    return axios.delete(API_URL + apiPath, { data: data })
  }
}
