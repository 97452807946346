import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { successNotify } from '@/apps/core/helpers/successNotify'

const modelName = 'NoteLine'
const apiPath = '/promo-note-validations/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state,
  noteStatusOptions: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  async deleteNoteLine ({ getters, commit, dispatch }, item) {
    commit('setLoading', true)
    try {
      const body = {
        action_name: 'delete_note_line',
        list_ids: [item.id]
      }
      await successNotify(apiClient.post(`/promo-note-validation-actions/`, body))
      return true
    } catch (error) {
      return false
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
