<i18n>
{
  "en": {
    "user": "User",
    "password": "Password",
    "requiredPassword": "Password is required",
    "requiredUser": "User is required",
    "forgotPassword": "I forgot my password",
    "restorePassword": "Restore your password",
    "restorePasswordEnterEmail": "Enter your email and if it is valid an email will be sent with a link to reset the password.",
    "sendEmail": "Send email",
    "login": "Login"
  },
  "es": {
    "user": "Usuario",
    "password": "Contraseña",
    "requiredPassword": "La contraseña es necesaria",
    "requiredUser": "El usuario es necesario",
    "forgotPassword": "He olvidado mi contraseña",
    "restorePassword": "Recuperar contraseña",
    "restorePasswordEnterEmail": "Introduce tu email y si es válido se enviará un correo con un link para reiniciar tu contraseña.",
    "sendEmail": "Enviar email",
    "login": "Iniciar sesión"
  }
}
</i18n>

<template>
  <v-app id="inspire">
    <vue-headful :title="'Login'" />
    <v-content class="mygradient">

      <v-container class="fill-height" fluid>

        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" md="4">
            <v-card class="elevation-12" v-if="!AUTH0_ENABLED">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>
                  <v-avatar tile height="32" width="32"><img :src="APPLICATION_LOGO" alt="logo"/></v-avatar>
                  {{APPLICATION_NAME}}
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text v-if="!AUTH0_ENABLED">
                <v-form @submit.prevent="login" id="login-form" v-model="validLoginForm">
                  <v-text-field
                    :label="$t('user')"
                    name="login"
                    prepend-icon="fa-user"
                    type="text"
                    v-model="user"
                    :rules="[v => !!v ||  $t('requiredUser')]"
                    required
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    :label="$t('password')"
                    name="password"
                    prepend-icon="fa-lock"
                    type="password"
                    v-model="password"
                    :rules="[v => !!v ||  $t('requiredPassword')]"
                    required
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions v-if="!AUTH0_ENABLED">
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" form="login-form" :disabled="!validLoginForm">Login</v-btn>
              </v-card-actions>
            </v-card>

                <div class="cont-auth" v-if="AUTH0_ENABLED">
                  <v-avatar tile height="200" width="200" class="cus-shadow"><img :src="APPLICATION_LOGO" alt="logo"/></v-avatar>
                  <v-btn large rounded width="300" color="primary" class="cus-btn" @click="loginAuth0">
                    {{$t('login')}}<v-icon right dark>fa-fingerprint</v-icon>
                  </v-btn>
                </div>

            <MadeWithLove is-login class="pt-5"/>
          </v-col>
        </v-row>
        <PrettyAlert :text="textAlert" v-model="showAlert" :color="colorAlert"/>

        <v-dialog v-model="showRestorePasswordDialog" width="500">
          <v-form @submit.prevent="resetPassword" id="restore-password-form" v-model="validRestorePasswordForm">
            <v-card>
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>{{$t('restorePassword')}}</v-toolbar-title>
              </v-toolbar>

              <v-card-subtitle>{{$t('restorePasswordEnterEmail')}}</v-card-subtitle>
              <v-card-text>
                <v-text-field
                  id="email"
                  label="Email"
                  name="email"
                  prepend-icon="fa-at"
                  type="email"
                  :rules="emailValidationRules"
                  required
                  v-model="email"
                />
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" type="submit" :disabled="!validRestorePasswordForm">{{$t('sendEmail')}}</v-btn>
              </v-card-actions>

            </v-card>
          </v-form>
        </v-dialog>

      </v-container>

    </v-content>

  </v-app>
</template>

<script>
import PrettyAlert from '../components/PrettyAlert'
import MadeWithLove from '../components/MadeWithLove'
import { APPLICATION_NAME, APPLICATION_LOGO, AUTH0_ENABLED, LOGIN_REDIRECT_URL } from '@/variables'
import axios from 'axios'
import i18n from '@/i18n'

export default {
  name: 'Login',
  components: {
    MadeWithLove,
    PrettyAlert
  },
  props: {
    authError: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      user: null,
      password: null,
      validLoginForm: false,

      showAlert: false,
      textAlert: '',
      colorAlert: 'info',

      // Restore password
      validRestorePasswordForm: false,
      email: null,
      emailValidationRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      ],
      showRestorePasswordDialog: false
    }
  },
  created () {
    if (this.authError) this.showAlertAction(i18n.tc('general.NoAuth'), 'error')
  },
  computed: {
    APPLICATION_NAME: () => APPLICATION_NAME,
    APPLICATION_LOGO: () => APPLICATION_LOGO,
    AUTH0_ENABLED: () => AUTH0_ENABLED,
    LOGIN_REDIRECT_URL: () => LOGIN_REDIRECT_URL
  },
  methods: {
    login: function () {
      // comment to test
      let username = this.user.trim()
      let password = this.password
      this.$store.dispatch('login', { username, password })
        .then(() => this.$router.push(this.LOGIN_REDIRECT_URL))
        .catch(() => this.showAlertAction('Error trying to login. Review your username and password.', 'info'))
    },
    resetPassword: function () {
      let email = this.email.trim()
      this.$store.dispatch('resetPassword', { email })
        .then(() => this.showAlertAction('Email sent. Review carefully in your inbox and in your spam folders.', 'success'))
        .catch(() => this.showAlertAction('Error trying to restore your password. Review that the entered email is valid.', 'error'))
      this.showRestorePasswordDialog = false
    },

    showAlertAction (text, color) {
      this.textAlert = text
      this.showAlert = true
      this.colorAlert = color
    },
    ShowRestorePasswordDialog () {
      this.showRestorePasswordDialog = true
    },
    loginAuth0: function () {
      this.$auth.loginWithPopup().then(token => {
        this.$auth.getUser().then(user => {
          this.$auth.getIdTokenClaims().then(token => {
            this.$store.state.auth.token = token.__raw
            this.$store.state.auth.user = user.email
            this.$store.state.auth.status = 'success'
            localStorage.setItem('token', token.__raw)
            localStorage.setItem('user', user.email)
            axios.defaults.headers.common['Authorization'] = `JWT ${token.__raw}`
            this.$router.push(this.LOGIN_REDIRECT_URL)
          })
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  .mygradient {
    background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, var(--v-primary-lighten3) 100%);
  }

  .cont-auth {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .cus-shadow {
    margin: 0 auto;
    filter: drop-shadow(10px 10px 15px rgba(0, 0, 0, 0.8));
  }

  .cus-btn {
    margin-top: 100px
  }
</style>
