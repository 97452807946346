import * as actions from './actions';
import { mutations } from './mutations';

export const unnotificationsModule = {
  state: {
    notifications: []
  },
  actions,
  mutations
};
