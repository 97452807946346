import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericCalendarStore } from '@/apps/core/store/genericCalendarStore'

const modelName = 'ClientsCalendar'
const apiPath = '/campaign-clients-calendar/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)
const genericCalendarStore = makeGenericCalendarStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  ...genericCalendarStore.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericCalendarStore.actions,
  getResourcesClientsCalendar ({ commit, state }, category) {
    state.loading = true
    return apiClient
      .get(`/get_resources_clients_calendar/${category}/`)
      .then(response => {
        let data = { ...state.currentItem, resources: response.data }
        commit('setCurrentItem', data)
        this.loading = true
      })
      .finally(() => {
        state.loading = false
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  ...genericCalendarStore.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
