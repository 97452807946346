import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'

const modelName = 'Detachment'
const apiPath = '/detachments/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  superCategoryOptions: [],
  categoryOptions: {}
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,
  getCategoryOptions ({ commit }) {
    return apiClient
      .get(apiPath + 'category/options/')
      .then(response => {
        if (response.data) {
          commit('setCategoryOptions', response.data)
        } else {
          console.error(`No possible category options found`)
        }
      })
  },
  getDetachment ({ commit }, pk) {
    return apiClient
      .get(`/detachments/${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setCategoryOptions (state, payload) {
    state.superCategoryOptions = Object.keys(payload)
    state.categoryOptions = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
