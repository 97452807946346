import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'

const modelName = 'CampaignTemplate'
const apiPath = '/promo-templates/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  getActionPath: '/promo-template-actions/'
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,

  async cloneItem ({ commit }, pk) {
    try {
      let response = await apiClient.post(apiPath + 'clone/' + pk)
      const { errors } = response.data
      if (errors) {
        console.error('error cloning ' + modelName)
        throw response
      }
      return response
    } catch (error) {
      console.error(error)
    }
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
