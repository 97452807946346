import i18n from '@/i18n'
import { RESPONSE_LEVEL } from '@/variables'
import store from '@/apps/core/store'
import { createNotification } from '@/lib/unnotificationsqueue'

export async function successNotify (petition, config = { customMessage: null }) {
  let notificationMessage = config.customMessage || ''
  let notificationLevel = ''
  try {
    const response = await petition
    if (response?.status === 200 || response?.status === 201 || response?.status === 204) {
      notificationMessage = notificationMessage || response.data?.message || i18n.tc('dialogs.savedNewValues')
      notificationLevel = response.data?.level || RESPONSE_LEVEL.SUCCESS
      await store.dispatch('addNotification', createNotification(notificationMessage, notificationLevel), { root: true })
    }
    return response
  } catch (err) {
    console.error(err)
  }
}
