import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import apiClient from './apiclient'

Vue.use(Vuex)

export default {
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || ''
  },
  mutations: {
    auth_request (state) {
      state.status = 'loading'
    },
    auth_success (state, loginData) {
      state.status = 'success'
      state.token = loginData.token
      state.user = loginData.user
    },
    auth_error (state) {
      state.status = 'error'
    },
    logout (state) {
      state.status = ''
      state.token = ''
      state.user = ''
    }
  },
  actions: {
    login ({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        apiClient.login(payload)
          .then(resp => {
            const token = resp.data.token
            commit('auth_success', { token: token, user: payload.username })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            reject(err)
          })
      })
    },

    logout ({ commit }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },

    resetPassword (_, payload) {
      return new Promise((resolve, reject) => {
        apiClient.resetPassword(payload)
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err)
          })
      })
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    user: state => state.user
  }
}
