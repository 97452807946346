export default {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#FFC72C',
        secondary: '#3F2021',
        anchor: '#3F2021',
        accent: '#3F2021'
      },
      dark: {}
    }
  },
  icons: {
    iconfont: 'fa'
  }
}
