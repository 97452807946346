import '@fortawesome/fontawesome-free/css/all.css' // Ensure you are using css-loader
import '@mdi/font/css/materialdesignicons.css'
import 'typeface-roboto/index.css' // vuetify / material design font
import './styles.css'

import Vue from 'vue'
import Vuetify from 'vuetify'
import i18n from '@/i18n'
import 'vuetify/dist/vuetify.min.css'
import { ENVIRONMENT } from '@/variables'

Vue.use(Vuetify)

let vuetifyEnvironment = require('./met').default
if (ENVIRONMENT === 'LOR') {
  vuetifyEnvironment = require('./lor').default
} else if (ENVIRONMENT === 'CAC') {
  vuetifyEnvironment = require('./cac').default
} else if (ENVIRONMENT === 'PNG') {
  vuetifyEnvironment = require('./png').default
} else if (ENVIRONMENT === 'SCH') {
  vuetifyEnvironment = require('./sch').default
}

let locale = i18n.locale
let vuetifyLocale = require(`vuetify/es5/locale/${locale}`).default
let lang = {
  locales: { [locale]: vuetifyLocale },
  current: locale
}

export default new Vuetify({ ...vuetifyEnvironment, lang })
