import i18n from '@/i18n'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'AgreementHistory'
const apiPath = '/agreement-history-records/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  requestParams: {
    limit: 5,
    offset: 0
  }
}

const actions = {
  /**
   * Fetches the paginated history and saves it in store (This could be a generic store module call)
   * - if "query" is undefined, last call's params are taken from store
   */
  async getHistory ({ state, commit }, { id, query }) {
    try {
      commit('setLoading', true)
      let requestParams = query || state.requestParams
      let response = await apiClient.get(`${apiPath}${id}/`, requestParams)
      commit('setItemList', { response: response.data, requestParams })
    } catch (error) {
      let alert = error.response && error.response.data
      throw alert || { level: RESPONSE_LEVEL.ERROR, message: i18n.tc('general.errorSomethingHappenedOptional') }
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  setLoading: baseCrud.mutations.setLoading,
  clearItems: baseCrud.mutations.clearItems,
  setItemList: baseCrud.mutations.setItemList
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
