import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

// import { RESPONSE_LEVEL } from '@/variables'
// import { createNotification } from '@/lib/unnotificationsqueue'

const modelName = 'Forecast'
const apiPath = ''
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state,
  forecastData: {},
  forecastLoading: false,
  forecastHideZeroItems: false
}

const getters = {
  ...baseCrud.getters,
  getForecastZeroItems: state => state.forecastHideZeroItems
}

const actions = {
  ...baseCrud.actions,

  getForecastList ({ commit }, type = 'distributor') {
    return apiClient
      .get(`/forecasts/?type__external_id=${type}`)
      .then(response => {
        return response.data.results
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        commit('setForecastLoading', false)
      })
  },

  getTimeHierarchyLevelByForecastId ({ commit }, pk) {
    return apiClient
      .get(`/forecast-time-hierarchy-levels/Mes/hierarchy-elements/?forecast_id=${pk}`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
      })
  },

  getForecastKams ({ commit }, obj) {
    return apiClient
      .get(`/forecast-distributor-hierarchy-levels/${obj.levelPk}/hierarchy-elements/?forecast_id=${obj.forecastId}&check-related-children=true`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        commit('setForecastLoading', false)
      })
  },

  getForecastBrands ({ commit }, forecastId) {
    return apiClient
      .get(`/forecast-product-hierarchy-levels/productos__marca/hierarchy-elements/`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        commit('setForecastLoading', false)
      })
  },

  getForecastSku ({ commit }, [forecastId, brandId]) {
    return apiClient
      .get(`/forecast-product-hierarchy-levels/productos__articulo/hierarchy-elements/?forecast_id=${forecastId}&ascendant_element_id=${brandId}`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        commit('setForecastLoading', false)
      })
  },

  getForecastCustomers ({ commit }, [customerLevel, kamPk, forecastId]) {
    return apiClient
      .get(`/forecast-distributor-hierarchy-levels/${customerLevel}/hierarchy-elements/?parent_id=${kamPk}&forecast_id=${forecastId}`)
      .then(response => {
        return response.data
      })
      .catch((error) => {
        console.error(error)
      }).finally(() => {
        commit('setForecastLoading', false)
      })
  },

  validateForecast ({ commit }, items) {
    return apiClient
      .post(`/forecast-actions/`, {
        action_name: 'cac_validate_lines',
        list_ids: [items]
      })
      .then(response => {
        console.log('Validate forecast response for', modelName, response.data)
        return response
      }).catch((error) => {
        if (error.response) {
          return error.response
        }
      })
  },

  invalidateForecast ({ commit }, items) {
    return apiClient
      .post(`/forecast-actions/`, {
        action_name: 'cac_invalidate_lines',
        list_ids: [items]
      })
      .then(response => {
        console.log('Invalidate forecast response for', modelName, response.data)
        return response
      }).catch((error) => {
        if (error.response) {
          return error.response
        }
      })
  },

  setForecastDataValues ({ commit }, forecastData) {
    commit('setForecastData', forecastData)
  },
  setForecastLoadingValue ({ commit }, value) {
    commit('setForecastLoading', value)
  },
  setForecastZeroItems ({ commit }, value) {
    commit('setForecastZeroItems', value)
  }
}

const mutations = {
  ...baseCrud.mutations,
  setForecastData (state, forecastData) {
    state.forecastData = forecastData
  },
  setForecastLoading (state, value) {
    state.forecastLoading = value
  },
  setForecastZeroItems (state, value) {
    state.forecastHideZeroItems = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
