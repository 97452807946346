import i18n from '@/i18n'
import { RESPONSE_LEVEL } from '@/variables'
import { createNotification } from '@/lib/unnotificationsqueue'

export function setup ({ axios, store, router }) {
  axios.interceptors.response.use((res) => {
    return res
  }, (error) => {
    let notificationMessage, notificationLevel
    const errorMessageFromResponse = error.response?.data?.message || error.response?.data?.error_message
    const errorLevel = error.response?.data?.level
    switch (error.response?.status) {
      case 400:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.petitionSupportError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.WARNING
        break
      case 401:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.NoAuth')
        notificationLevel = errorLevel || RESPONSE_LEVEL.ERROR
        break
      case 403:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.permissionsError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.WARNING
        break
      case 404:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.notFoundError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.WARNING
        break
      case 414:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.uriTooLargeError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.WARNING
        break
      case 500:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.contactSupportError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.ERROR
        break
      default:
        notificationMessage = errorMessageFromResponse || i18n.tc('general.contactSupportError')
        notificationLevel = errorLevel || RESPONSE_LEVEL.ERROR
    }
    store.dispatch('addNotification', createNotification(notificationMessage, notificationLevel), { root: true })
    throw error
  })
}
