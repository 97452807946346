import Vue from 'vue'
import App from './App.vue'
import router from '@/apps/core/router'
import store from '@/apps/core/store'
import axios from 'axios'
import { setup as authSetup } from '@/lib/unlogin/config'
import { setup as interceptorSetup } from '@/apps/core/helpers/responseInterceptor'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import { AUTH0_DOMAIN as domain, AUTH0_CLIENT_ID as clientId, AUTH0_ENABLED } from './variables'
import { Auth0Plugin } from '@/lib/unlogin/auth'
import Lget from 'lodash/get'
import VueTippy, { TippyComponent } from 'vue-tippy'
import VueAxios from 'vue-axios'
import vueHeadful from 'vue-headful'
import LiquorTree from 'liquor-tree'

import './number-locales'
import './apps/core/helpers/filters'

import 'tippy.js/themes/light.css'
import 'tippy.js/themes/light-border.css'
import 'tippy.js/themes/google.css'
import 'tippy.js/themes/translucent.css'

Vue.config.productionTip = false
Vue.use(VueAxios, axios)

Vue.use(VueTippy)
Vue.component('tippy', TippyComponent)

Vue.use(vueHeadful)
// Library for hierarchiesFiltersSelector
Vue.use(LiquorTree)
Vue.component('vue-headful', vueHeadful)

Vue.mixin({
  methods: { Lget }
})

if (AUTH0_ENABLED) {
  Vue.use(Auth0Plugin, {
    domain,
    clientId,
    onRedirectCallback: appState => {
      router.push(
        appState && appState.targetUrl
          ? appState.targetUrl
          : window.location.pathname
      )
    }
  })
}

new Vue({
  router,
  store,
  created () {
    // 401  (auth error) Response interceptor
    authSetup({ axios, store, router })
    interceptorSetup({ axios, store, router })
  },
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
