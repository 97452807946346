import i18n from '@/i18n'

import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'Endorsement'
const apiPath = '/endorsements/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getEndorsement ({ commit }, pk) {
    return apiClient
      .get(`/endorsements/${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  createEndorsement ({ commit }, toSendParams = { }) {
    const alertMessage = {}
    return apiClient
      .post(`/endorsements/`, toSendParams)
      .then(response => {
        alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
        alertMessage['id'] = response.data.id
        return alertMessage
      })
      .catch(error => {
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        }
        return alertMessage
      })
  },
  updateEndorsement ({ commit, state }, pk) {
    const alertMessage = {}
    return apiClient
      .patch(`/endorsements/${pk}/`, state.currentItem)
      .then(response => {
        if (response.data.level === RESPONSE_LEVEL.WARNING) {
          alertMessage['level'] = response.data.level
          alertMessage['message'] = response.data.message
        } else {
          commit('setCurrentItem', response.data)
          alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
          alertMessage['message'] = i18n.tc('dialogs.savedNewValues')
        }
        return alertMessage
      })
      .catch(error => {
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
        }
        throw alertMessage
      })
  },
  deleteEndorsement ({ commit }, pk) {
    const alertMessage = {}
    return apiClient
      .delete(`/endorsements/${pk}/`)
      .then(response => {
        if (alertMessage.hasOwnProperty('level')) {
          alertMessage['level'] = response.data.level
          alertMessage['message'] = response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
          alertMessage['message'] = i18n.tc('dialogs.successDelete')
        }
        return alertMessage
      })
      .catch((error) => {
        console.error(error)
        alertMessage['level'] = RESPONSE_LEVEL.ERROR
        alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
        return alertMessage
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
