const TYPES = {
  INFO: 'info',
  WARN: 'warning',
  SUCCESS: 'success',
  ERROR: 'error',
};

const getMessagesList = (message) => {
  if (!message) throw new Error('Error creating notification: message param is mandatory!');
  if (typeof message !== 'string' && !Array.isArray(message)) {
    throw new Error('Error creating notification: message must be a string or an array of strings!');
  }
  if (Array.isArray(message)) {
    if (message.length === 0) throw new Error('Error creating notification: message array must not be empty!');
    if (Array.isArray(message) && message.some((str) => typeof str !== 'string')) throw new Error('Error creating notification: message array must contains strings!');
  }

  return typeof message === 'string' ? [message] : message;
};

const setNotifications = (message, props = {}, timeout) => {
  const messages = getMessagesList(message);
  return ({
    id: new Date().getTime(),
    top: true,
    ...props,
    messages,
    timeout: timeout || 0
  });
};

export const createNotification = (message, type, props, timeout) => ({
  color: Object.values(TYPES).indexOf(type) >= 0 ? type : TYPES.INFO,
  ...setNotifications(message, props, timeout),
});
export const createInfoNotification = (message, props, timeout) => ({
  color: TYPES.INFO,
  ...setNotifications(message, props, timeout),
});
export const createSuccessNotification = (message, props = {}, timeout) => ({
  color: TYPES.SUCCESS,
  ...setNotifications(message, props, timeout),
});
export const createWarningNotification = (message, props = {}, timeout) => ({
  color: TYPES.WARN,
  ...setNotifications(message, props, timeout),
});
export const createErrorNotification = (message, props = {}, timeout) => ({
  color: TYPES.ERROR,
  ...setNotifications(message, props, timeout),
});
