import Vue from 'vue'
import VueI18n from 'vue-i18n'
import merge from 'lodash/merge'

import { APP_LANGUAGES, IS_PNG, LOCALES_CONFIG } from './variables'

Vue.use(VueI18n)

function loadLocaleLanguage () {
  return IS_PNG ? 'en' : process.env.VUE_APP_I18N_LOCALE || 'es'
}

/**
 * Loads a locale file in a directory or returns and empty object.
 * @param dir directory path from ./src
 * @param required used to display error if file if not found
 * @param language language to load in the locale dir
 * @returns {Object} object with locale data for that dir and language
 */
const getResource = ({ dir, required }, language) => {
  try {
    console.debug(`Loading locales at "./${dir}/${language}.json"`)
    return require(`./${dir}/${language}.json`)
  } catch (e) {
    if (required) console.error(`Error loading "${language}" locale in "${dir}":`, e)
    return {}
  }
}

/**
 * Loads all locales found in config and merges them into one object.
 * @returns {object} object with all locales from config merged
 */
const loadLocaleFiles = () => {
  const messages = APP_LANGUAGES.flatMap(language => LOCALES_CONFIG.map((config) => {
    const messages = getResource(config, language)
    return { [language]: messages }
  }))
  // Merge takes into account order of messages array, preserving the config of the last item of the array
  // in case that a key is already present
  return merge({}, ...messages)
}

function numberFormats () {
  const numberFormat = {
    'es': {
      currency: {
        style: 'currency', currency: 'EUR'
      },
      percentage: {
        style: 'percent'
      }
    },
    'us': {
      currency: {
        style: 'currency', currency: 'EUR'
      },
      percentage: {
        style: 'percent'
      }
    }
  }
  return numberFormat
}

export default new VueI18n({
  locale: loadLocaleLanguage(),
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleFiles(),
  numberFormats: numberFormats(),
  silentTranslationWarn: process.env.NODE_ENV === 'production'
})
