import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Distributor'
const apiPath = '/chains_catalog/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getItem ({ commit }, pk) {
    return apiClient
      .get('/distributor_catalog_detail/', {
        external_id: pk
      })
      .then(response => {
        if (response.data.results.length === 1) {
          commit('setCurrentItem', response.data.results[0])
        } else {
          console.error(`No results for ${modelName}`, pk)
        }
      })
  },
  postItem ({ commit }, item) {
    return apiClient
      .post('/distributor_catalog_detail/', {
        [modelName]: [item]
      })
      .then(response => {
        console.log('post response for', modelName, response.data)
        // eslint-disable-next-line camelcase
        const { errors, identification_ids } = response.data
        if (errors > 0 || identification_ids.length !== 1) {
          console.error('error creating ' + modelName)
          throw response
        }
        return response
      })
  },
  getInitialTemplate ({ commit }) {
    const initialTemplateSkeleton = {
      level: 1
    }
    commit('setCurrentItem', initialTemplateSkeleton)
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
