import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'

const modelName = 'PromoDetachment'
const apiPath = '/promo-detachments/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  superCategoryOptions: [],
  categoryOptions: {},
  requestParams: {
    ...baseCrud.state.requestParams,
    type: null
  }
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,
  getItemList ({ commit, state }, payload = {}) {
    commit('setLoading', true)
    const toSendParams = {
      limit: state.requestParams.limit,
      offset: state.requestParams.offset,
      ...payload
    }
    return apiClient
      .get(apiPath, toSendParams)
      .then(response => {
        commit('setItemList', { requestParams: toSendParams, response: response.data })
      })
      .finally(() => commit('setLoading', false))
  },
  getDetachment ({ commit }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  getDetachments ({ commit }, promoId) {
    const toSendParams = {
      limit: 500,
      offset: 0,
      promo: promoId
    }
    return apiClient.get(apiPath, toSendParams)
  },
  setBaseType ({ state }, value) {
    state.requestParams.type = value
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
