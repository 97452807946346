import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Endorsement'
const apiPath = '/old-endorsements/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  validEndorsementTypes: [],
  validContributions: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getValidEndorsementTypes ({ commit }) {
    return apiClient.get('/endorsement_types/').then(response => {
      const results = response.data
      if (results['results']) {
        commit('setValidEndorsementTypes', {
          endorsementTypes: results['results']
        })
      } else {
        console.error('No results for endorsement types')
      }
    })
  },
  getValidContributions ({ commit }, pk) {
    return apiClient.get('/old-contributions/?agreement__external_id=' + pk).then(response => {
      const results = response.data
      if (results['results']) {
        commit('setValidContributions', {
          contributions: results['results']
        })
      } else {
        console.error('No results for contributions associated to agreement "' + pk + '"')
      }
    })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setValidEndorsementTypes (state, payload) {
    state.validEndorsementTypes = payload.endorsementTypes
  },
  setValidContributions (state, payload) {
    state.validContributions = payload.contributions
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
