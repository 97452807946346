import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'AgreementDocs'
const apiPath = '/agreement_docs/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

/**
 * May add 'agreement' state that reference's the external_id
 * (Possibly better to use the agreement's module currentItem instead)
 */

const state = {
  ...baseCrud.state,
  getActionPath: '/actions_agreement_docs/'
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getDocument ({ commit }, pk) {
    return new Promise((resolve, reject) => {
      return apiClient
        .get(apiPath + pk, null, { responseType: 'blob' })
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
