import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Distributor'
const apiPath = '/distributors-master/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getDistributor ({ commit }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  createDistributor ({ state }) {
    return apiClient
      .post(`${apiPath}`, state.currentItem)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error)
      })
  }
}
const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
