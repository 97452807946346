import Vue from 'vue'
import Lget from 'lodash/get'

export default function makeCrudModule (apiClient, modelName, apiPath, primaryKey = 'external_id') {
  return {
    // initial state
    state: {
      loading: false,
      items: [],
      dynamicDialogSelectedItems: [],
      crudTableConfig: {},
      count: 0,
      currentItem: {},
      extra_fields: {},
      /** This params are sent in getList requests to the backend (django rest framework) **/
      requestParams: {
        limit: 500, // set a sane maximum as default
        offset: 0
        // this object will Vue.set more properties for request with filters and options
      }
    },
    // getters
    getters: {
      getDynamicDialogSelectedItems (state) {
        return state.dynamicDialogSelectedItems
      },
      editableHeaders (state) {
        return state.crudTableConfig.headers.filter(header => header.editable && header.selector)
      },
      getHeaders (state) {
        return state.crudTableConfig.headers
      },
      getLoading (state) {
        return state.loading
      },
      getFilterItems (state) {
        return state.crudTableConfig.filterItems
      },
      getStickyColumns (state) {
        return state.crudTableConfig.stickyColumns
      },
      getCustomActions (state) {
        return state.crudTableConfig.customActions
      },
      getCrudTableConfig (state) {
        return state.crudTableConfig
      }
    },

    // actions
    actions: {
      /* retrieve a list of items (using pagination) */
      getItemList ({ commit, state }, payload = {}) {
        commit('setLoading', true)
        const toSendParams = {
          limit: state.requestParams.limit,
          offset: state.requestParams.offset,
          ...payload
        }
        return apiClient
          .get(apiPath, toSendParams)
          .then(response => {
            commit('setItemList', { requestParams: toSendParams, response: response.data })
          })
          .finally(() => commit('setLoading', false))
      },
      getAgreementItemList ({ commit, state }, payload = {}) {
        commit('setLoading', true)
        const toSendParams = {
          limit: state.requestParams.limit,
          offset: state.requestParams.offset,
          ...payload
        }
        return apiClient
          .get(payload.ratePath, toSendParams)
          .then(response => {
            commit('setItemList', { requestParams: toSendParams, response: response.data })
          })
          .finally(() => commit('setLoading', false))
      },
      reloadItemList ({ commit, state }, params = { avoidLoader: false }) {
        if (!params.avoidLoader) {
          commit('setLoading', true)
        }
        return apiClient
          .get(apiPath, state.requestParams)
          .then(response => {
            commit('setItemList', { requestParams: state.requestParams, response: response.data })
          })
          .finally(() => commit('setLoading', false))
      },
      /* retrieve a single one by primary key */
      getItem ({ commit }, pk) {
        return apiClient
          .get(apiPath, {
            [primaryKey]: pk
          })
          .then(response => {
            if (response.data.results.length === 1) {
              commit('setCurrentItem', response.data.results[0])
            } else {
              console.error(`no results for ${modelName}`, pk)
            }
          })
      },
      /* Clears the current item for a new fresh one */
      clearCurrentItem ({ commit }) {
        commit('resetCurrent')
      },
      /* Updates the current item */
      updateCurrentItem ({ commit }, payload) {
        commit('setCurrentItem', payload)
      },
      /* post (update) a single item */
      // eslint-disable-next-line no-unused-vars
      postItem ({ commit }, item) {
        return apiClient
          .post(apiPath, {
            [modelName]: [item]
          })
          .then(response => {
            console.log('post response for', modelName, response.data)
            // eslint-disable-next-line camelcase
            const { errors, identification_ids } = response.data
            if (errors > 0 || identification_ids.length !== 1) {
              console.error('error creating ' + modelName)
              throw response
            }
            return response
          })
      },
      /* post (update) a single item wit key value form */
      // eslint-disable-next-line no-unused-vars
      postFormItem ({ commit }, item) {
        return apiClient
          .post(apiPath, item)
          .then(response => {
            console.log('post response for', modelName, response.data)
            // eslint-disable-next-line camelcase
            const { errors, identification_ids } = response.data
            // eslint-disable-next-line camelcase
            if (errors > 0 || (typeof identification_ids !== 'undefined' && identification_ids.length !== 1)) {
              console.error('error creating ' + modelName)
              throw response
            }
            return response
          })
      },
      /* put (update) a single item wit key value form */
      // eslint-disable-next-line no-unused-vars
      putFormItem ({ commit }, item) {
        return apiClient
          .put(apiPath + item.id + '/', item)
          .then(response => {
            console.log('put response for', modelName, response.data)
            const { errors } = response.data
            if (errors > 0) {
              console.error('error updating ' + modelName)
              throw response
            }
            return response
          })
      },
      /* post (update) a batch of items */
      // eslint-disable-next-line no-unused-vars
      postManyItems ({ commit }, itemList) {
        return apiClient
          .post(apiPath, {
            [modelName]: itemList
          })
          .then(response => {
            console.log('post response for', modelName, response.data)
            // eslint-disable-next-line camelcase
            const { errors, identification_ids } = response.data
            if (errors > 0 || (itemList.length <= 10 && identification_ids.length !== itemList.length)) {
              console.error('error creating ' + modelName)
              throw response
            }
            return response
          })
      },
      /* deletes a list of item */
      // eslint-disable-next-line no-unused-vars
      deleteItems ({ commit }, items) {
        return apiClient
          .delete(apiPath, {
            [modelName]: items
          })
          .then(response => {
            console.log('delete response for', modelName, response.data)
            const { errors, deletes } = response.data
            if (errors > 0 || deletes !== items.length) {
              console.error('error deleting ' + modelName)
              throw response
            }
            return response
          })
      },
      /* delete a single item by id */
      // eslint-disable-next-line no-unused-vars
      deleteItem ({ commit }, item) {
        return apiClient
          .delete(apiPath + item.id + '/', {})
          .then(response => {
            console.log('delete response for', modelName, response.data)
            const { errors } = response.data
            if (errors > 0) {
              console.error('error deleting ' + modelName)
              throw response
            }
            return response
          })
      },
      clearItemList ({ commit }) {
        commit('clearItems')
      },
      updateItemList ({ commit }, items) {
        commit('updateItems', items)
      },
      setLoading ({ commit }, value) {
        commit('setLoading', value)
      },
      downloadAjaxFile ({ commit }, { apiPath, data, filenameHeader }) {
        return apiClient
          .post(apiPath, data, { responseType: 'blob' })
          .then(res => {
            // Easy way to download file using ajax.
            // Checkout link https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
            const url = window.URL.createObjectURL(new Blob([res.data], { type: res.data.type }))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${res.headers[filenameHeader]}`)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          })
      },

      /**
       * When activating 'fetch-editable-options' prop.
       * @param state
       * @param commit
       * @param editableHeaders
       */
      fetchEditableFieldOptions ({ state, getters, commit }) {
        getters.editableHeaders.forEach(({ selectorUrl, modelToUse, optionsLocation }) => {
          apiClient.get(selectorUrl).then(response => {
            commit('setEditableFieldOptions', { field: modelToUse.split('.')[1], options: Lget(response, optionsLocation) })
          })
        })
      }

    },
    // mutations
    mutations: {
      addDynamicDialogSelectedItem (state, value) {
        // Cleaning objects that belong to the same parent if exists
        state.dynamicDialogSelectedItems = state.dynamicDialogSelectedItems.filter(
          el => el.parentId !== value.parentId)

        // Adding new object to array
        state.dynamicDialogSelectedItems.push(value)
      },
      setLoading (state, value) {
        state.loading = value
      },
      setItemList (state, { requestParams, response }) {
        state.items = response.results
        state.count = response.count
        state.crudTableConfig = response.crudTableConfig
        Vue.set(state, 'requestParams', requestParams)
        Vue.set(state, 'extra_fields', response.extra_fields)
      },
      setCurrentItem (state, payload) {
        Vue.set(state, 'currentItem', payload)
      },
      resetCurrent (state) {
        state.currentItem = {}
      },
      clearItems (state) {
        state.items = []
      },
      updateItems (state, items) {
        state.items = items
      },
      setEditableFieldOptions (state, payload) {
        state[payload.field] = payload.options
      }
    },
    namespaced: true
  }
}
