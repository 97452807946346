import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'ProductCategory'
const apiPath = '/product-category/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getItem ({ commit }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
