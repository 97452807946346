import i18n from '@/i18n'

import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'CampaignTemplate'
const apiPath = '/campaign-templates/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,
  createCampaignFromTemplateId ({ commit }, templateId) {
    const alertMessage = {}
    let body = { 'action_name': 'create_campaign_from_template', 'list_ids': [templateId], 'filters': {} }
    return apiClient
      .post(`/campaign-template-actions/`, body)
      .then(response => {
        if (response.data.level === RESPONSE_LEVEL.SUCCESS) {
          alertMessage['level'] = response.data.level
          alertMessage['message'] = response.data.message
          alertMessage['redirect_route_name'] = response.data.redirect_route_name
          alertMessage['redirect_route_url_params'] = response.data.redirect_route_url_params
          return alertMessage
        } else {
          let error = { response }
          throw error
        }
      })
      .catch(error => {
        console.error(error)
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        }
        throw alertMessage
      })
  },
  createCampaignTemplate ({ commit }, toSendParams = { date: null, dhe: null }) {
    const alertMessage = {}
    return apiClient
      .post(`/campaign-templates/`, toSendParams)
      .then(response => {
        alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
        alertMessage['id'] = response.data.id
        return alertMessage
      })
      .catch(error => {
        console.error(error)
        if (error.response.data.hasOwnProperty('level')) {
          alertMessage['level'] = error.response.data.level
          alertMessage['message'] = error.response.data.message
        } else {
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        }
        return alertMessage
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
