import moment from 'moment'

/**
 API always sends dates with bounds '[)' (close-open), so it always adds 1 day to upper dates,
   in order to fix this we subtract 1 day to the received date
   @param apiData {Object} Response data from API
   @param fields {Array} Field names where dates are stored
 */
export const getBoundsFixer = (apiData, fields) => {
  fields.forEach(field => {
    if (apiData[field]) {
      const dateSelected = apiData[field]
      const dateParsed = JSON.parse(dateSelected)
      const isDateOpen = (/[)]/).test(dateParsed.bounds)
      if (isDateOpen && dateParsed) {
        dateParsed.upper = moment(dateParsed.upper).subtract(1, 'days').format('YYYY-MM-DD')
        apiData[field] = JSON.stringify(dateParsed)
      }
    }
  })

  return apiData
}

export const sendBoundsFixer = (apiData, fields) => {
  fields.forEach(field => {
    if (apiData[field]) {
      const dateSelected = apiData[field]
      const dateParsed = JSON.parse(dateSelected)
      const isDateOpen = (/[)]/).test(dateParsed.bounds)
      if (isDateOpen && dateParsed) {
        dateParsed.upper = moment(dateParsed.upper).add(1, 'days').format('YYYY-MM-DD')
        dateParsed.bounds = '[)'
        apiData[field] = JSON.stringify(dateParsed)
      }
    }
  })

  return apiData
}
