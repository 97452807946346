import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'
import { getBoundsFixer, sendBoundsFixer } from '@/apps/dido/helpers/boundsChecker'

const modelName = 'Campaign'
const apiPath = '/campaigns/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters,
  getCampaignDescriptions: state => CampaignPksString => {
    let CampaignPks = new Set(CampaignPksString.split(','))
    let CampaignDescriptions = []

    for (let campaign of state.items) {
      if (CampaignPks.has(String(campaign.id))) {
        CampaignDescriptions.push(campaign.description)
      }
    }

    if (CampaignDescriptions.length !== CampaignPks.size) {
      CampaignDescriptions.push('(' + String(CampaignPks.size - CampaignDescriptions.length) + ' más)')
    }

    return CampaignDescriptions
  }
}

const actions = {
  ...baseCrud.actions,
  async getCampaign ({ commit, dispatch, rootState }, pk) {
    let campaignData
    const response = await apiClient.get(`/campaigns/${pk}/`)
    const dateFields = ['service_date', 'store_promo_date', 'order_date']
    campaignData = getBoundsFixer(response.data, dateFields)
    if ('calculated__promo_id_field' in campaignData) {
      await dispatch('promos/getPromo', campaignData.calculated__promo_id_field, { root: true })
      campaignData = { ...campaignData, promo: rootState['promos'].currentItem }
    }
    commit('setCurrentItem', campaignData)
  },
  createCampaign ({ commit }, toSendParams = { date: null, dhe: null }) {
    const alertMessage = {}
    return apiClient
      .post(`/campaigns/`, toSendParams)
      .then(response => {
        alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
        alertMessage['id'] = response.data.id
        return alertMessage
      })
      .catch(error => {
        console.error(error)
      })
  },
  async updateCampaign ({ commit, state }, pk) {
    try {
      const campaignData = { ...state.currentItem }
      const dateFields = ['service_date', 'store_promo_date', 'order_date']
      const campaignDateBoundsFixed = sendBoundsFixer(campaignData, dateFields)
      const response = await apiClient.patch(`/campaigns/${pk}/`, campaignDateBoundsFixed)
      commit('setCurrentItem', response.data)
      return response
    } catch (e) {
      console.error(e)
    }
  },
  deleteCampaign ({ commit }, pk) {
    return apiClient
      .delete(`/campaigns/${pk}/`)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error)
      })
  },
  getCampaignKpis ({ commit, state }, pk) {
    return apiClient
      .get(`/campaign-kpis/${pk}/`)
      .then(response => {
        let data = { ...state.currentItem, ...response.data }
        commit('setCurrentItem', data)
      })
      .catch(error => {
        console.log(error)
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
