const moduleName = 'juno'
const modulePath = `/${moduleName}`

/* Note on dynamic import :

  We use them to only load the components when the route is visited.

  For now webpack has some limitations and we CANT put much of the import path in a variable
  so we have to repeat the ../../modules ....
 */

export default [
  {
    path: modulePath + '/agreement',
    name: 'AgreementListOld',
    component: () => import('../../apps/' + moduleName + '/views/AgreementList.vue'),

    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/agreement/:id',
    name: 'AgreementDetailOld',
    component: () => import('../../apps/' + moduleName + '/views/AgreementDetail.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/products/',
    name: 'JunoProductList',
    component: () => import('../../apps/' + moduleName + '/views/ProductList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/products/:id',
    name: 'JunoProductDetail',
    component: () => import('../../apps/' + moduleName + '/views/ProductDetail.vue'),
    meta: {
      requiresAuth: true
    }
  }
]
