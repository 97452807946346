import { RESPONSE_LEVEL } from '@/variables'
import i18n from '@/i18n'

export const genericUpdateWithBody = async (commit, state, pk, body, method, apiClient, apiPath) => {
  const alertMessage = {}
  let putBody = body || state.currentItem
  try {
    let response
    if (method === 'put') {
      response = await apiClient.put(`${apiPath}${pk}/`, putBody)
    } else if (method === 'patch') {
      response = await apiClient.patch(`${apiPath}${pk}/`, putBody)
    } else {
      throw TypeError('genericUpdateWithBody method parameter only accept "put" or "patch"')
    }
    if (response.data.level === RESPONSE_LEVEL.WARNING) {
      alertMessage['level'] = response.data.level
      alertMessage['message'] = response.data.message
    } else {
      commit('setCurrentItem', response.data)
      alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
      alertMessage['message'] = i18n.tc('dialogs.savedNewValues')
    }
    return alertMessage
  } catch (error) {
    console.error(error)
    if (error.response.data.hasOwnProperty('level')) {
      alertMessage['level'] = error.response.data.level
      alertMessage['message'] = error.response.data.message
    } else {
      alertMessage['level'] = RESPONSE_LEVEL.ERROR
      alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
    }
    throw alertMessage
  }
}
