<template>

  <v-app id="app">
    <RouterView/>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import { LAST_VERSION } from '@/variables'

export default {
  name: 'App',
  components: {
  },
  data: () => ({
    leftDrawer: null,
    rightDrawer: null,
    app_links: [
      { title: 'Admin Site', path: '/unadmin' }
    ]
  }),
  computed: {
    ...mapGetters(['isLoggedIn', 'user'])
  },
  methods: {
    logout: function () {
      this.$store.dispatch('logout')
        .then(() => this.$router.push('/login'))
        .catch(err => alert(err))
    },
    login: function () {
      this.$router.push('/login')
    },
    showNotification: function () {
      // import apiClient from '@/lib/unlogin/store/apiclient' import this in the import section-
      // apiClient.get('theUrlToNotification'), call the api to get the notifications can use the
      // response handler to show te notifications
      // setTimeout(this.showNotification, 2000) here is to recall the function
    }
  },
  mounted () {
    console.log('version:' + LAST_VERSION)
    const lastVersionSaved = localStorage.getItem('deployedVersion')

    if (lastVersionSaved !== LAST_VERSION || lastVersionSaved === null) {
      localStorage.setItem('deployedVersion', LAST_VERSION)

      window.location.reload(true)
    }
    // this.showNotification() should see if have user logged
  }
}
</script>

<style lang="scss">
html {
  overflow: auto;
  box-sizing: border-box;
}
</style>
