<template>
  <v-snackbar
    v-model="visible"
    top
    :color="color"
  >
    {{text}}
    <v-btn
      dark
      text
      @click="visible = false"
    >
      Close
    </v-btn>
  </v-snackbar>
</template>

<script>

export default {
  name: 'Alert',

  props: ['text', 'value', 'color'],

  methods: {
    close: function () {
      this.visible = false
    }
  },

  computed: {
    visible: {
      get: function () {
        return this.value
      },
      set: function (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>

</style>
