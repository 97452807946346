import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Rappel'
const apiPath = '/rappels/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: []
}

const getters = {
  ...baseCrud.getters,
  settlementTypesList: state => {
    return Object.entries(state.settlementTypes).map(pair => ({
      text: pair[1],
      value: pair[0]
    }))
  }
}

const actions = {
  ...baseCrud.actions,
  getSettlementTypes ({ commit }) {
    return apiClient
      .get('/settlement_types_eur_box_rappel/', { offset: 0, limit: 9999 }) // We dont need pagination here
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setSettlementTypes', { settlementTypes: results['results'] })
        } else {
          console.log('Y peto', results)
          console.error('No results for settlement types for rappel')
        }
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setSettlementTypes (state, payload) {
    state.settlementTypes = payload.settlementTypes
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
