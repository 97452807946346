import i18n from '@/i18n'
import apiClient from '@/lib/unlogin/store/apiclient'

const state = {
  headers: [{ text: i18n.t('general.name'), value: 'breadcrumb' }, { text: i18n.t('general.included'), value: 'included' }],
  count: 0,
  filters: [],
  loadingFilters: false,
  filtersList: [],
  loadingFiltersList: false
}

const getters = {
  getHierarchyHeaders (state) {
    return state.headers
  },
  getLoadingFilters (state) {
    return state.loadingFilters
  },
  getFilters (state) {
    return state.filters
  },
  includedFilters (state) {
    return state.filters ? state.filters.filter(item => item.included).map(item => item.hierarchy_element) : []
  },

  excludedFilters (state) {
    return state.filters ? state.filters.filter(item => !item.included).map(item => item.hierarchy_element) : []
  }
}

const actions = {
  async getHierarchyFiltersList ({ state, commit, getters }, payload) {
    commit('setLoadingFiltersList', true)
    let listEndpoint = payload.listEndpoint
    let queryParams = { include: getters.includedFilters.join(','), exclude: getters.excludedFilters.join(',') }
    commit('clearFiltersList')
    let response = await apiClient.get(listEndpoint, queryParams)
    let data = response.data.results.map(item => ({ value: item.id, text: item.name }))
    commit('setFiltersList', data)
    commit('setLoadingFiltersList', false)
  },

  async getHierarchyFilters ({ commit, state }, endpoint) {
    try {
      commit('setLoadingFilters', false)
      let response = await apiClient.get(endpoint)
      if (response.data) {
        commit('setFilters', response.data)
      }
    } finally {
      commit('setLoadingFilters', false)
    }
  }
}

const mutations = {
  setLoadingFilters (state, payload) {
    state.loadingFilters = payload
  },

  setLoadingFiltersList (state, payload) {
    state.loadingFiltersList = payload
  },

  setFilters (state, payload) {
    state.filters = payload
  },

  setFiltersList (state, payload) {
    state.filtersList = payload
  },

  clearFilters (state) {
    state.filters = []
  },

  clearFiltersList (state) {
    state.filtersList = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
