import i18n from '@/i18n'
import { RESPONSE_LEVEL } from '@/variables'
import { getBoundsFixer, sendBoundsFixer } from '@/apps/dido/helpers/boundsChecker'
import { genericUpdateWithBody } from '@/apps/core/store/actions'

export const makeGenericStore = (apiClient, apiPath, storeConfig = {}) => {
  return {
    actions: {

      async getItem ({ commit }, pk) {
        try {
          let response = await apiClient.get(`${apiPath}${pk}/`)
          const data = response.data
          const dateFields = ['service_date', 'store_promo_date', 'order_date']
          const dataBoundsFixed = getBoundsFixer(data, dateFields)
          commit('setCurrentItem', dataBoundsFixed)
        } catch (error) {
          console.error(error)
        }
      },

      async getAndReturnItem ({ commit }, pk) {
        try {
          let response = await apiClient.get(`${apiPath}${pk}/`)
          const data = response.data
          const dateFields = ['service_date', 'store_promo_date', 'order_date']
          const dataBoundsFixed = getBoundsFixer(data, dateFields)
          return dataBoundsFixed
        } catch (error) {
          console.error(error)
        }
      },

      async createItem () {
        const alertMessage = {}
        try {
          let response = await apiClient.post(`${apiPath}`)
          response.data['level'] = RESPONSE_LEVEL.SUCCESS
          return response.data
        } catch (error) {
          console.error(error)
          if (error.response.data.hasOwnProperty('level')) {
            alertMessage['level'] = error.response.data.level
            alertMessage['message'] = error.response.data.message
          } else {
            alertMessage['level'] = RESPONSE_LEVEL.ERROR
            alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
          }
          return alertMessage
        }
      },

      async createItemWithBody ({ commit }, body) {
        const alertMessage = {}
        try {
          let response = await apiClient.post(apiPath, body)
          return response.data
        } catch (error) {
          if (error.response.data && error.response.data.level) {
            alertMessage['level'] = error.response.data.level
            alertMessage['message'] = error.response.data.message
          } else {
            alertMessage['level'] = RESPONSE_LEVEL.ERROR
            alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
          }
          throw alertMessage
        }
      },

      /** primaryKeyTuple example: { campaign: pk } */
      createItemFromPk ({ commit }, primaryKeyTuple) {
        const alertMessage = {}
        return apiClient.post(apiPath, primaryKeyTuple)
          .then(response => {
            alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
            alertMessage['id'] = response.data.id
            return alertMessage
          })
          .catch(error => {
            if (error.response.data.hasOwnProperty('level')) {
              alertMessage['level'] = error.response.data.level
              alertMessage['message'] = error.response.data.message
            } else {
              alertMessage['level'] = RESPONSE_LEVEL.ERROR
              alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
            }
            return alertMessage
          })
      },

      async patchItem ({ commit, state }, pk) {
        const alertMessage = {}
        const dateFields = ['service_date', 'store_promo_date', 'order_date']
        const dataBoundsFixed = sendBoundsFixer(state.currentItem, dateFields)
        try {
          let response = await apiClient.patch(`${apiPath}${pk}/`, dataBoundsFixed)
          if (response.data.level === RESPONSE_LEVEL.WARNING) {
            alertMessage['level'] = response.data.level
            alertMessage['message'] = response.data.message
          } else {
            commit('setCurrentItem', response.data)
            alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
            alertMessage['message'] = i18n.tc('dialogs.savedNewValues')
          }
          return alertMessage
        } catch (error) {
          console.error(error)
          if (error.response.data.hasOwnProperty('level')) {
            alertMessage['level'] = error.response.data.level
            alertMessage['message'] = error.response.data.message
          } else {
            alertMessage['level'] = RESPONSE_LEVEL.ERROR
            alertMessage['message'] = i18n.tc('dialogs.errorWhenSaving')
          }
          return alertMessage
        }
      },

      async putItemWithBody ({ commit, state }, { pk, body }) {
        return genericUpdateWithBody(commit, state, pk, body, 'put', apiClient, apiPath)
      },

      async patchItemWithBody ({ commit, state }, { pk, body }) {
        return genericUpdateWithBody(commit, state, pk, body, 'patch', apiClient, apiPath)
      },

      async deleteItem ({ commit }, pk) {
        const alertMessage = {}
        try {
          let response = await apiClient.delete(`${apiPath}${pk}/`)
          if (alertMessage.hasOwnProperty('level')) {
            alertMessage['level'] = response.data.level
            alertMessage['message'] = response.data.message
          } else {
            alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
            alertMessage['message'] = i18n.tc('dialogs.successDelete')
          }
          return alertMessage
        } catch (error) {
          console.error(error)
          alertMessage['level'] = RESPONSE_LEVEL.ERROR
          alertMessage['message'] = i18n.tc('general.error')
          return alertMessage
        }
      },

      async deleteItemThrow ({ commit }, pk) {
        const alertMessage = {}
        try {
          let response = await apiClient.delete(`${apiPath}${pk}/`)
          if (response.data.level === RESPONSE_LEVEL.WARNING) {
            alertMessage['level'] = response.data.level
            alertMessage['message'] = response.data.message
          } else {
            alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
            alertMessage['message'] = i18n.tc('dialogs.successDelete')
          }
          return alertMessage
        } catch (error) {
          if (error.response.data.hasOwnProperty('level')) {
            alertMessage['level'] = error.response.data.level
            alertMessage['message'] = error.response.data.message
          } else {
            console.error(error)
            alertMessage['level'] = RESPONSE_LEVEL.ERROR
            alertMessage['message'] = i18n.tc('general.error')
          }
          throw alertMessage
        }
      }
    }
  }
}
