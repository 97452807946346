import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'
import didoRoutes from '@/apps/dido/routes'
import junoRoutes from '@/apps/juno/routes'
import juno2Routes from '@/apps/juno2/routes'
import titanRoutes from '@/apps/titan/routes'
import deimosRoutes from '@/apps/deimos/routes'
import uncustomuiRoutes from '@/lib/uncustomui/routes'
import baseRoutes from '@/lib/unlogin/routes'

Vue.use(VueRouter)

const welcome = [
  {
    path: '/welcome',
    name: 'cosmos',
    component: () => import('../views/Welcome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/',
    component: () => import('../views/Welcome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '',
    component: () => import('../views/Welcome.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue')
  }
]

const customPNGRoutes = [
  {
    path: '/assignPromoDetachmentToSeveralNotes',
    name: 'AssignPromoDetachmentToSeveralNotes',
    component: () => import('../components/AssignPromoDetachmentToSeveralNotes.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reclassNoteLine',
    name: 'ReclassNoteLine',
    component: () => import('../components/ReclassNoteLine.vue'),
    meta: {
      requiresAuth: true
    }
  }
]

const routes = [
  ...baseRoutes,
  ...didoRoutes,
  ...titanRoutes,
  ...junoRoutes,
  ...juno2Routes,
  ...deimosRoutes,
  ...uncustomuiRoutes,
  ...welcome,
  ...customPNGRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// This handles the meta 'requiresAuth'
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
