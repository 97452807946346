import * as MutationTypes from './mutationTypes';


const addNotification = (state, notification) => {
  state.notifications.push(notification);
};
const removeNotification = (state) => {
  state.notifications.pop();
};
const dismissNotifications = (state) => {
  state.notifications = [];
};

export const mutations = {
  [MutationTypes.ADD_NOTIFICATION]: addNotification,
  [MutationTypes.REMOVE_NOTIFICATION]: removeNotification,
  [MutationTypes.DISMISS_NOTIFICATIONS]: dismissNotifications,
};
