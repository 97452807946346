/* eslint-disable */
import i18n from '@/i18n'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'

const modelName = 'NoteLine'
const apiPath = '/campaign-note-lines/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,
  getNotes ({ commit }, note_id) {
    return new Promise(resolve => {
      apiClient.get(`/notes/${note_id}/`).then(res => {
        resolve({ data: [ res.data ] })
      })
    })
  },
  getPromosFilteredByNoteId ({ commit }, note_id) {
    return new Promise((resolve) => {
      apiClient.get(`/promos-for-notes/?note_id=${note_id}`).then(response =>
        resolve({ data: response.data.results}))
    })
  },
  getPromo ({ dispatch }, promo_id) {
    return new Promise((resolve) => {
      apiClient.get(`/promos/${promo_id}/`).then(response => {
        resolve({ data: response.data })
      })
    })
  },
  getNoteFieldChoicesPng ({ dispatch, commit }, obj ) {
    return Promise.all([
      apiClient.get('/custom_fields/choices/100/'),
      apiClient.get('/note-field-choices/status/')
    ])
      .then(responses => responses.map(response => response.data))
      .catch(() => {
        let error = { message: i18n.tc('general.errorSomethingHappenedOptional') }
        throw error
      })
  },
  getPromoChoicesPng ({ dispatch }, obj) {
    return Promise.all([
      apiClient.get('/custom_fields/choices/100/'),
      apiClient.get('/note-field-choices/status/')
    ])
      .then(responses => responses.map(response => response.data))
      .catch(() => {
        let error = { message: i18n.tc('general.errorSomethingHappenedOptional') }
        throw error
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
