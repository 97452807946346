export default {
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#000000',
        secondary: '#847155',
        anchor: '#847155',
        accent: '#847155'
      },
      dark: {}
    }
  },
  icons: {
    iconfont: 'fa'
  }
}
