import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'HierarchiesFiltersList'
const apiPath = ''
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = () => {
  return {
    ...baseCrud.state,
    requestParams: { limit: 10, offset: 0 },
    listEndpoint: '',
    totalCount: 0
  }
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,

  async getItemList ({ commit, state }, payload) {
    let listEndpoint = payload.listEndpoint
    let queryParams = payload.queryParams
    commit('setLoading', true)
    try {
      let toSendParams = {}
      if (listEndpoint && queryParams) {
        toSendParams = { limit: state.requestParams.limit, offset: state.requestParams.offset, ...queryParams }
      } else {
        toSendParams = { ...state.requestParams, ...payload }
        toSendParams.search = payload.search ? payload.search : undefined
      }
      if (listEndpoint) {
        commit('setListEndpoint', listEndpoint)
      }
      let response = await apiClient.get(state.listEndpoint, toSendParams)
      commit('setItemList', { requestParams: toSendParams, response: response.data })
      /** If further filters are applied, this may need a cleaner check or even a flag passed by the component */
      if (!toSendParams.search) {
        commit('setTotalCount', response.data.count)
      }
      return response
    } finally {
      commit('setLoading', false)
    }
  }
}

const mutations = {
  ...baseCrud.mutations,

  clearItems (state) {
    state.count = 0
    state.totalCount = 0
    state.items = []
  },

  setTotalCount (state, payload) {
    state.totalCount = payload
  },

  setListEndpoint (state, payload) {
    state.listEndpoint = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
