import moment from 'moment'

export const makeGenericCalendarStore = (apiClient, apiPath, storeConfig = {}) => {
  return {

    state: {
      isCurrentItemFetched: false,
      DATE_FORMAT: 'DD/MM/YYYY'
    },

    mutations: {
      setCurrentItemFetched (state) {
        state.isCurrentItemFetched = true
      },
      updateCalendarResources (state, resources) {
        let showRowsWithoutPromotions = state.currentItem.url_query.includes('show_empty_resources=true')
        let showLastYearPromotions = state.currentItem.url_query.includes('previous_year=true')
        let resourcesToShow = resources
        if (showRowsWithoutPromotions && !showLastYearPromotions) {
          resourcesToShow = resources.filter(resource => {
            return resource.id.includes('__CY')
          })
        }
        state.currentItem.resourcesToShow = resourcesToShow
      }
    },
    actions: {
      async getCalendar ({ state, commit }, { setDefaultFilters = true }) {
        let response
        response = await apiClient.get(apiPath)
        if (response.data) {
          let { filters, ...data } = response.data
          /*
          if (setDefaultFilters) {
            data.filters = filters
          }
          */
          data.filters = (setDefaultFilters) ? filters : { ...filters, ...state.currentItem.filters }
          commit('setCurrentItem', { ...state.currentItem, ...data })
          return state.currentItem
        } else {
          console.error(`No possible category options found`)
        }
      },

      async filterCalendar ({ commit, state }) {
        state.loading = true
        try {
          let response = await apiClient.get(`${state.currentItem.events_url}?${state.currentItem.url_query}`)
          let resources = state.currentItem.resources
          let data = { ...state.currentItem, events: response.data }
          commit('setCurrentItem', data)
          commit('updateCalendarResources', resources)
          // this.loading = true
        } finally {
          state.loading = false
        }
      },

      async getContentPopUp ({ state }, pk) {
        let response = await apiClient.get(`${state.currentItem.pop_up_url}?promo_id=${pk}`)
        return response.data
      },

      async editEvent ({ state }, payload) {
        try {
          let eventId = payload.event.id
          let resourcesId = payload.newResource ? payload.newResource.id : ''
          let dateStart = moment(payload.event.start).format(state.DATE_FORMAT)
          let dateEnd = moment(payload.event.end).format(state.DATE_FORMAT)
          return await apiClient
            .get(`/edit-calendar?promo_id=${eventId}&date_start=${dateStart}&date_end=${dateEnd}&banner=${resourcesId}`)
        } catch {
          return payload.revert()
        }
      }
    }
  }
}
