import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'
// import { getBoundsFixer } from '@/apps/dido/helpers/boundsChecker'

const modelName = 'AgreementLine'
const apiPath = '/agreement-lines/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  getActionPath: '/actions_agreement_line/'
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  createOrUpdateAgreementLine ({ commit }, toSendParams = { date: null, dhe: null }) {
    const alertMessage = {}
    return new Promise((resolve, reject) => {
      apiClient.post('/agreement-line-update-or-create/', toSendParams)
        .then(response => {
          alertMessage['level'] = RESPONSE_LEVEL.SUCCESS
          alertMessage['id'] = response.data.id
          resolve(alertMessage)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
