import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Location'
const apiPath = '/locations/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  areas: [],
  validLevels: {},
  groupChildren: [],
  validGroupItems: [],
  locations: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
