import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'User'
const apiPath = '/users/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  managerContactList: []
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getManagers ({ commit }) {
    return apiClient.get(apiPath, { position: 'gerente' }).then(response => {
      const results = response.data
      if (results['results']) {
        commit('setManagers', results['results'])
      } else {
        console.error('No results for manager contacts')
      }
    })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setManagers (state, payload) {
    state.managerContactList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
