import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { isUndefined, isNull } from 'lodash'

const modelName = 'Distributor'
const apiPath = '/distributor.list/'
const apiInternalGroupingPath = '/internal_grouping/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  areas: [],
  validLevels: {},
  groupChildren: [],
  validGroupItems: [],
  locations: [],
  locationsByIndex: {},
  managements: [],
  creditor: [],
  validCreditors: [],
  validDistributors: [],
  selectedArea: []
}

const getters = {
  ...baseCrud.getters,
  validLevelsList: state => {
    return Object.entries(state.validLevels)
      .filter(x => [1, 2, 3, 4].includes(x[1]))
      .map(pair => ({
        text: pair[0],
        value: pair[1]
      }))
  },
  validRealLevelsList: state => {
    // Some levels like group are strictly intern, and do not come from SAP. This list will not show the intern levels
    return Object.entries(state.validLevels)
      .filter(x => [1, 2, 4].includes(x[1]))
      .map(pair => ({
        text: pair[0],
        value: pair[1]
      }))
  }
}

const actions = {
  ...baseCrud.actions,
  getValidDistributorList ({ commit }, payload) {
    return apiClient.get('/distributor-list/', { limit: 50, ...payload }).then(response => response.data)
  },
  /* retrieve a single one by primary key */
  getItem ({ commit }, pk) {
    return apiClient
      .get('/distributor_detail/', { // detail url
        external_id: pk
      })
      .then(response => {
        if (response.data.results.length === 1) {
          commit('setCurrentItem', response.data.results[0])
        } else {
          console.error(`no results for ${modelName}`, pk)
        }
      })
  },
  getValidLevels ({ commit }) {
    return apiClient.get('/distributor_levels/').then(response => {
      const results = response.data
      if (results['distributor_levels']) {
        commit('setValidLevels', {
          validLevels: results['distributor_levels']
        })
      } else {
        console.error('No results for distributor types')
      }
    })
  },
  getAreas ({ commit }) {
    return apiClient
    // TODO this request may need pagination
      .get('/locations/', { level: 4, offset: 0, limit: 9999 }) // For now level 4 is the only valid Areas
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setAreas', { areas: results['results'] })
        } else {
          console.error('No results for distributor areas')
        }
      })
  },
  getLocations ({ commit }, params) {
    if (isUndefined(params) || isNull(params)) {
      params = {}
    }
    if (isUndefined(params.limit) || isNull(params.limit)) {
      params.limit = 9999
    }
    return apiClient
    // TODO this request may need pagination
      .get('/locationFilterList/', { ...params, offset: 0 }) // Without level to get all locations
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setLocations', results['results'])
        } else {
          console.error('No results for distributor locations')
        }
      })
  },
  getLocationsByIndex ({ commit }, params) {
    return apiClient
      .get('/locationsFilterByIndex/', { ...params, offset: 0 }) // Without level to get all locations
      .then(response => {
        const results = response.data
        if (results['results']) {
          results['locationIndex'] = params.locationIndex
          commit('setLocationsByIndex', results)
        } else {
          console.error('No results for distributor locations')
        }
      })
  },
  getManagements ({ commit }) {
    return apiClient
      .get('/managementFilterList/', { offset: 0, limit: 9999 }) // Without level to get all managements
      .then(response => {
        const results = response.data.results
        if (results) {
          commit('setManagements', results)
        } else {
          console.error('No results for managements')
        }
      })
  },

  getGroupChildren ({ commit }, groupKey) {
    return apiClient
      .get(apiPath, { offset: 0, limit: 9999, group__external_id: groupKey })
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setChildren', results['results'])
        } else {
          console.error('No results for distributor (group children)')
        }
      })
  },

  getValidGroupItems ({ commit }, payload) {
    return apiClient
      .get(apiPath, { level: 4, offset: 0, limit: 9999, ...payload })
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setValidGroupItems', results['results'])
        } else {
          console.error('No results for distributors (group items)')
        }
      })
  },

  getValidCreditors ({ commit, dispatch }, payload) {
    return dispatch('getValidDistributorList', payload)
      .then(data => {
        if (data['results']) {
          commit('setValidCreditors', { creditors: data['results'] })
        } else {
          console.error('No possible creditors found')
        }
      })
  },

  getCreditor ({ commit }, pk) {
    return apiClient
      .get('/distributor_detail/', { // detail url
        external_id: pk
      })
      .then(response => {
        if (response.data.results.length === 1) {
          return response.data.results[0]
        } else {
          console.error(`no results for ${modelName}`, pk)
        }
      })
  },
  postCreditor ({ commit }, item) {
    return apiClient
      .post('/distributors/', {
        [modelName]: [item]
      })
      .then(response => {
        console.log('post response for', modelName, response.data)
        // eslint-disable-next-line camelcase
        const { errors, identification_ids } = response.data
        if (errors > 0 || identification_ids.length !== 1) {
          console.error('error creating ' + modelName)
          throw response
        }
        return response
      })
  },
  postCreditorFormItem ({ commit }, item) {
    return apiClient
      .post('/distributors/', item)
      .then(response => {
        console.log('post response for', modelName, response.data)
        // eslint-disable-next-line camelcase
        const { errors, identification_ids } = response.data
        // eslint-disable-next-line camelcase
        if (errors > 0 || (typeof identification_ids !== 'undefined' && identification_ids.length !== 1)) {
          console.error('error creating ' + modelName)
          throw response
        }
        return response
      })
  },

  postManyItems ({ commit }, itemList) {
    return apiClient
      .post('/distributors/', {
        [modelName]: itemList
      })
      .then(response => {
        console.log('post response for', modelName, response.data)
        // eslint-disable-next-line camelcase
        const { errors, identification_ids } = response.data
        if (errors > 0 || (itemList.length <= 10 && identification_ids.length !== itemList.length)) {
          console.error('error creating ' + modelName)
          throw response
        }
        return response
      })
  },

  clearCreditorParams () {
    state.creditor = []
    state.validCreditors = []
  },

  getValidDistributors ({ commit }, payload) {
    return apiClient
      .get('/distributor-list/', { ...payload, limit: 50 })
      .then(response => {
        state.validDistributors = []
        const results = response.data
        if (results['results']) {
          commit('setValidDistributors', { distributors: results['results'] })
        } else {
          console.error('No possible creditors found')
        }
      })
  },

  createCampaignInternalGrouping ({ commit }, payload) {
    return apiClient
      .post(apiInternalGroupingPath, payload)
      .then(response => {
        console.log('post response for InternalGrouping', response.data)
      })
      .catch(error => {
        console.log('Error while posting InternalGrouping', error)
      })
  },

  getSelectedDistributors ({ commit }, payload) {
    return apiClient
      .get('/distributor_bulk_set_list/', { ...payload, limit: 50 })
      .then(response => {
        return response.data['results']
      })
  },

  getDistributorParams ({ commit }, payload) {
    return apiClient
      .get('/distributor-list/', { ...payload })
      .then(response => {
        state.validDistributors = []
        const results = response.data
        if (results['results']) {
          return results['results'][0]
        } else {
          console.error('No possible creditors found')
        }
      })
  },

  setDistributorSelectedArea ({ commit }, payload) {
    commit('setDistributorArea', { area: payload })
  },

  clearValidDistributors () {
    state.validDistributors = []
  }
}

const mutations = {
  ...baseCrud.mutations,
  setChildren (state, payload) {
    state.groupChildren = payload
  },
  setValidGroupItems (state, payload) {
    state.validGroupItems = payload
  },
  setAreas (state, payload) {
    state.areas = payload.areas
  },
  setValidLevels (state, payload) {
    state.validLevels = payload.validLevels
  },
  setLocations (state, payload) {
    state.locations = payload
  },
  setLocationsByIndex (state, payload) {
    state.locationsByIndex[payload['locationIndex']] = payload['results']
  },
  setManagements (state, payload) {
    state.managements = payload
  },
  setCreditor (state, payload) {
    state.creditor = payload
  },
  setValidCreditors (state, payload) {
    state.validCreditors = payload.creditors
  },
  setValidDistributors (state, payload) {
    state.validDistributors = payload.distributors
  },
  setDistributorArea (state, payload) {
    state.selectedArea = payload.area
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
