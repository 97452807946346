import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Contribution'
const apiPath = '/old-contributions/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const currentItemBaseState = {
  external_id: null
}

const state = {
  ...baseCrud.state,
  settlementTypes: [],
  categories: [],
  currentItem: {
    ...currentItemBaseState
  }
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getValidTypes ({ commit }) {
    return apiClient.get('/contribution_types/').then(response => {
      const results = response.data
      if (results['results']) {
        commit('setValidTypes', {
          types: results['results']
        })
      } else {
        console.error('No results for contribution types')
      }
    })
  },
  getValidPortfolios ({ commit }) {
    return apiClient.get('/product_categories/?level=-4').then(response => {
      const results = response.data
      if (results['results']) {
        commit('setValidPortfolios', {
          portfolios: results['results']
        })
      } else {
        console.error('No results for portfolios')
      }
    })
  }
}

const mutations = {
  ...baseCrud.mutations,
  resetCurrent (state) {
    state.currentItem = { ...currentItemBaseState }
  },
  setValidTypes (state, payload) {
    state.validTypes = payload.types
  },
  setValidPortfolios (state, payload) {
    state.categories = payload.portfolios
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
