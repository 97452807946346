import i18n from '@/i18n'
import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import { RESPONSE_LEVEL } from '@/variables'

const modelName = 'Topic'
const apiPath = '/topics/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')

const state = {
  ...baseCrud.state
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  getItem ({ commit }, pk) {
    return apiClient
      .get(`${apiPath}${pk}/`)
      .then(response => {
        commit('setCurrentItem', response.data)
      })
      .catch((error) => {
        console.error(error)
      })
  },
  createTopic ({ state }) {
    const alertMessage = {}
    return apiClient
      .post(`${apiPath}`, state.currentItem)
      .then(response => {
        return response
      })
      .catch(error => {
        console.error(error)
        alertMessage['level'] = RESPONSE_LEVEL.ERROR
        alertMessage['message'] = i18n.tc('general.errorSomethingHappenedOptional')
        return alertMessage
      })
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
