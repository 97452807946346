import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

import { makeGenericStore } from '@/apps/core/store/genericApiViewStore'
import { IS_CAC } from '@/variables'

const modelName = 'ProductOrders'
const apiPath = '/active-products-choices/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath, 'id')
const genericStore = makeGenericStore(apiClient, apiPath)

const state = {
  ...baseCrud.state,
  productsList: [],
  selectedProduct: {},
  hierarchyListEndpoint: '',
  requestParams: { limit: 10, offset: 0 },
  filtersStoreModule: '',
  IsProductReplacement: false,
  showTable: true
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  ...genericStore.actions,

  /* REPEATED */

  async getItemList ({ commit, state, rootGetters }, payload) {
    let queryParams = payload.queryParams
    let include = rootGetters[`${state.filtersStoreModule}/includedFilters`].join(',')
    let exclude = rootGetters[`${state.filtersStoreModule}/excludedFilters`].join(',')
    let isProductReplacement = this.state.productsFilter.IsProductReplacement

    if (include === '' && exclude === '' && !isProductReplacement) {
      commit('setLoading', false)
      return
    }

    commit('setLoading', true)
    try {
      let toSendParams = {}
      if (queryParams) {
        toSendParams = {
          limit: state.requestParams.limit,
          offset: state.requestParams.offset,
          ...queryParams,
          include,
          exclude
        }
      } else {
        toSendParams = { ...state.requestParams, ...payload, include, exclude }
        toSendParams.search = payload.search ? payload.search : undefined
      }
      if (IS_CAC && !isProductReplacement) {
        toSendParams.props__product_order = IS_CAC
      }

      let response = await apiClient.get(state.hierarchyListEndpoint, toSendParams)
      commit('setItemList', { requestParams: toSendParams, response: response.data })
      return response
    } finally {
      commit('setLoading', false)
    }
  }
}
const mutations = {
  ...baseCrud.mutations,

  setFiltersStoreModule (state, storeModule) {
    state.filtersStoreModule = storeModule
  },

  setSelectedProduct (state, payload) {
    state.selectedProduct = payload
  },

  setHierarchyListEndpoint (state, payload) {
    state.hierarchyListEndpoint = payload
  },

  setIsProductReplacement (state, payload) {
    state.IsProductReplacement = payload
  },

  setShowTable (state, payload) {
    state.showTable = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
