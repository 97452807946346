const moduleName = 'juno2'
const modulePath = `/${moduleName}`

export default [
  {
    path: modulePath + '/agreements',
    name: 'AgreementList',
    component: () => import('../../apps/' + moduleName + '/views/agreement/AgreementList/AgreementList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: modulePath + '/agreement/:id',
    name: 'AgreementDetail',
    component: () => import('../../apps/' + moduleName + '/views/agreement/AgreementDetail/AgreementDetail.vue'),
    meta: {
      requiresAuth: true
    }
  }
]
