const moduleName = 'deimos'
const modulePath = `/${moduleName}`

export default [
  {
    path: `${modulePath}/delegations`,
    name: 'BudgetList',
    component: () => import('../../apps/' + moduleName + '/views/BudgetList/BudgetList.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: `${modulePath}/delegations/:id`,
    name: 'BudgetDetail',
    component: () => import('../../apps/' + moduleName + '/views/BudgetDetail/BudgetDetail.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: `${modulePath}/forecastingProduct`,
    name: 'ForecastingByProduct',
    component: () => import('../../apps/' + moduleName + '/views/ForecastingByProduct/ForecastingByProduct.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: `${modulePath}/forecastingDistributor`,
    name: 'ForecastingByDistributor',
    component: () => import('../../apps/' + moduleName + '/views/ForecastingByDistributor/ForecastingByDistributor.vue'),
    meta: {
      requiresAuth: true
    },
    props: true
  }
]
