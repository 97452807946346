import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Status'
const apiPath = '/status/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  statusList: []
}

const getters = {
  ...baseCrud.getters,
  statusList: state => state.statusList,
  statusListLen: state => state.statusList.length
}

const actions = {
  ...baseCrud.actions,
  getStatusList ({ commit }, params) {
    return apiClient.get(apiPath, params)
      .then(res => {
        const data = res.data
        if (data['results']) {
          commit('setStatusList', data['results'])
        } else {
          console.error('No results for status list')
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  setStatusList (state, statuses) {
    state.statusList = []
    for (let status of statuses) {
      state.statusList.push(status)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
