import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'
import moment from 'moment'

const modelName = 'ProductsCalendar'
const apiPath = '/campaign-products-calendar/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

const state = {
  ...baseCrud.state,
  DATE_FORMAT: 'DD/MM/YYYY'
}

const getters = {
  ...baseCrud.getters
}

const actions = {
  ...baseCrud.actions,
  editEvent ({ state }, payload) {
    let eventId = payload.event.id
    let resourcesId = payload.newResource ? payload.newResource.id : ''
    let dateStart = moment(payload.event.start).format(state.DATE_FORMAT)
    let dateEnd = moment(payload.event.end).format(state.DATE_FORMAT)

    return apiClient
      .get(`/edit-calendar?promo_id=${eventId}&date_start=${dateStart}&date_end=${dateEnd}&banner=${resourcesId}`)
      .catch(() => {
        return payload.revert()
      })
  },
  getDateOfCalendar ({ commit }, info) {
    let isLastYear = info.resource.id.includes('_LY')
    var dateStr = info.dateStr

    if (isLastYear) {
      dateStr = moment(info.date).subtract(1, 'years').format(state.DATE_FORMAT_UPDATE)
    }
    return dateStr
  }
}

const mutations = {
  ...baseCrud.mutations
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
