import contentDisposition from 'content-disposition'

import makeCrudModule from '@/lib/uncrudtable/store/crudfactory'
import apiClient from '@/lib/unlogin/store/apiclient'

const modelName = 'Agreement'
const apiPath = '/old-agreements/'
const baseCrud = makeCrudModule(apiClient, modelName, apiPath)

function getDateNow () {
  const d = Date.now()
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  let ho = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false }).format(d)
  let mi = new Intl.DateTimeFormat('en', { minute: 'numeric' }).format(d)
  let se = new Intl.DateTimeFormat('en', { second: 'numeric' }).format(d)

  return ye + mo + da + ho + mi + se
}

function base64toBlob (base64Data, contentType, sliceSize = 512) {
  const byteCharacters = atob(base64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }
  return byteArrays
}

const CONTENT_TYPE_EXTENSION_MAP = {
  'text/csv': 'csv',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx'
}

// We need this bc agreements needs to have reactive data in its distributor
const currentItemBaseState = {
  external_id: null,
  distributor: {},
  newDistributorGroup: [],
  newDistributor: '',
  status: {},
  next_state: null
}

const state = {
  ...baseCrud.state,
  currentItem: {
    ...currentItemBaseState
  },
  validRates: [],
  validBeneficiaries: [],
  validSettlementTypesEurBoxRappel: [],
  validSettlementTypesBaseRappel: [],
  finalStatusList: [],
  clientGroup: [],
  messageData: {}
}

const getters = {
  ...baseCrud.getters,
  isCurrentItemFetched (state) {
    return !!state.currentItem.external_id
  }
}

const actions = {
  ...baseCrud.actions,
  exportAsExcel ({ _ }, itemList) {
    apiClient.post('/actions_agreement_export/xlsx/', {
      ids: itemList
    }).then(response => {
      const contentType = response.headers['content-type']
      if (Object.keys(CONTENT_TYPE_EXTENSION_MAP).includes(contentType)) {
        let fileName = ''
        if ('content-disposition' in response.headers) {
          try {
            let disposition = contentDisposition.parse(response.headers['content-disposition'])
            fileName = (disposition.parameters && disposition.parameters.filename) ? disposition.parameters.filename : undefined
          } catch (e) {
            console.error('Install content-disposition in the project.')
          }
        }
        let confirmedfileName = fileName === '' ? response.headers['x-filename'] : `${getDateNow()}.${CONTENT_TYPE_EXTENSION_MAP[contentType]}`
        let data = response.data
        if (contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
          data = base64toBlob(data, contentType)
        } else {
          data = [data]
        }
        let blob = new Blob(data, { type: contentType })
        let link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = confirmedfileName
        link.click()
      }
    })
  },
  postBeneficiaryClient ({ _ }, itemList) {
    return apiClient.post(`${apiPath}beneficiaries/add/`, {
      [modelName]: itemList
    })
  },
  setPaymentTypeCondition ({ _ }, agreementExternalId) {
    return apiClient.post(`${apiPath}beneficiaries/crco/`, {
      'external_id': agreementExternalId
    })
  },
  getClientGroup ({ commit, state }, externalId) {
    return apiClient
      .get('/agreements/clients/hierarchy/', {
        offset: 0,
        limit: 10,
        agreement__external_id: state.currentItem.external_id,
        external_id: externalId
      }).then(response => {
        commit('setClientGroup', response.data)
      })
  },
  modifyAcpCondition ({ _ }, itemList) {
    return apiClient.post(`${apiPath}modify/acp/`, {
      [modelName]: itemList
    })
  },
  getFinalStatusItemList ({ commit, state }, payload = {}) {
    const toSendParams = {
      limit: state.requestParams.limit,
      offset: state.requestParams.offset,
      ...payload
    }
    return apiClient
      .get(`${apiPath}final_status/`, toSendParams)
      .then(response => {
        commit('setFinalStatusItemList', response.data.results)
      })
  },
  /* retrieve a single one by primary key */
  getItem ({ commit }, pk) {
    return apiClient
      .get('/agreement_detail/', {
        external_id: pk
      })
      .then(response => {
        if (response.data.results.length === 1) {
          commit('setCurrentItem', response.data.results[0])
          commit('setExtraFields', response.data.extra_fields)
        } else {
          console.error(`no results for ${modelName}`, pk)
        }
      })
  },
  getValidRates ({ commit }) {
    return apiClient
      .get('/old-rates/', { offset: 0, limit: 9999 }) // We dont need pagination here
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setValidRates', { rates: results['results'] })
        } else {
          console.error('No results for rates')
        }
      })
  },
  getValidSettlementTypesEurBoxRappel ({ commit }) {
    return apiClient
      .get('/settlement_types_eur_box_rappel/', { offset: 0, limit: 9999 }) // We dont need pagination here
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setValidSettlementTypesEurBoxRappel', { settlementTypesEurBoxRappel: results['results'] })
        } else {
          console.error('No results for settlement types for eur/box rappel')
        }
      })
  },
  getValidSettlementTypesBaseRappel ({ commit }) {
    return apiClient
      .get('/settlement_types_base_rappel/', { offset: 0, limit: 9999 }) // We dont need pagination here
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setValidSettlementTypesBaseRappel', { settlementTypesBaseRappel: results['results'] })
        } else {
          console.error('No results for settlement types for base rappel')
        }
      })
  },
  closeAndCloneAgreements ({ commit }, payload) {
    return apiClient
      .post('/agreements/closeandclone/', { 'Agreement': payload })
  },
  closeAgreements ({ commit }, payload) {
    return apiClient
      .post('/agreements/close/', { 'Agreement': payload })
  },
  cloneAgreements ({ commit }, payload) {
    return apiClient
      .post('/agreements/clone/', { 'Agreement': payload })
  },
  getValidBeneficiaries ({ commit }, payload) {
    return apiClient
      .get('/distributor_detail/', { ...payload })
      .then(response => {
        const results = response.data
        if (results['results']) {
          commit('setValidBeneficiaries', { beneficiaries: results['results'] })
        } else {
          console.error('No possible beneficiaries found')
        }
      })
  },
  getValidBeneficiariesLocal ({ _ }, payload) {
    return apiClient
      .get('/distributor_detail/', { ...payload })
      .then(response => {
        const results = response.data
        if (results['results']) {
          return results['results']
        } else {
          console.error('No possible beneficiaries found')
          return []
        }
      })
  },
  setResetCurrentItem ({ commit }) {
    commit('resetCurrent')
  },
  approveListFromPendingReview ({ commit, state }, payload = {}) {
    const toSendParams = payload
    return apiClient
      .post(`/aggrements/approve_agreement_from_pending_review`, toSendParams)
      .then(res => {
        state.messageData = res
      })
  },
  expireListFromPendingReview ({ commit, state }, payload = {}) {
    const toSendParams = payload
    return apiClient
      .post(`/aggrements/expire_agreement_from_pending_review`, toSendParams)
      .then(res => {
        state.messageData = res
      })
  }
}

const mutations = {
  ...baseCrud.mutations,
  // We need this bc agreements needs to have reactive data in its distributor
  resetCurrent (state) {
    state.currentItem = { ...currentItemBaseState }
  },
  setValidRates (state, payload) {
    state.validRates = payload.rates
  },
  setValidBeneficiaries (state, payload) {
    state.validBeneficiaries = payload.beneficiaries
  },
  setValidSettlementTypesEurBoxRappel (state, payload) {
    state.validSettlementTypesEurBoxRappel = payload.settlementTypesEurBoxRappel
  },
  setValidSettlementTypesBaseRappel (state, payload) {
    state.validSettlementTypesBaseRappel = payload.settlementTypesBaseRappel
  },
  setExtraFields (state, payload) {
    state.extra_fields = payload
  },
  setFinalStatusItemList (state, payload) {
    state.finalStatusList = payload
  },
  setClientGroup (state, payload) {
    state.clientGroup = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
