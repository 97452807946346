import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/lib/unlogin/store/auth'
import { unnotificationsModule as unnotifications } from '@/lib/unnotificationsqueue'
import * as junoStoreModules from '@/apps/juno/store'
import * as titanStoreModules from '@/apps/titan/store'
import * as didoStoreModules from '@/apps/dido/store'
import * as deimosStoreModules from '@/apps/deimos/store'
import * as juno2StoreModules from '@/apps/juno2/store'
import hierarchiesFilterSelectorStore from '@/apps/core/store/hierarchiesFilterSelectorStore'
import hierarchiesProductStore from '@/apps/core/store/hierarchiesProductStore'
import hierarchiesFiltersList from '@/apps/core/store/hierarchiesFiltersList'
import apiClient from '@/lib/unlogin/store/apiclient'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    userProfile: [],
    modules: [],
    modulesExtra: [],
    miniSideBar: true, // collapsed by default
    navigationDrawerSections: [],
    clientName: ''
  },
  mutations: {
    setUser (state, value) {
      state.userProfile = value
    },
    setModules (state, value) {
      state.modules = value
    },
    setModulesExtra (state, value) {
      state.modulesExtra = value
    },
    setMiniSideBar (state, value) {
      state.miniSideBar = value
    },
    setNavigationDrawerSections (state, value) {
      value.forEach(x => {
        x.title = x.title.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
      })
      state.navigationDrawerSections = value
    },
    setClientName (state, value) {
      state.clientName = value
    }
  },
  actions: {
    changeMiniSideBar ({ commit }, value) {
      commit('setMiniSideBar', value)
    },
    getUser ({ commit }) {
      apiClient.get('/user-profiles/')
        .then(response => {
          commit('setUser', response.data.results[0])
        })
    },
    async getCosmosModuleByUser ({ commit }) {
      const response = await apiClient.get('/cosmos-modules/')
      commit('setModules', response.data.results && response.data.results.modules)
      commit('setModulesExtra', response.data.results && response.data.results['modules_extra'])
    },
    getBaseLayoutInfo ({ commit }, url) {
      return apiClient
        .get(`${url}`)
        .then(response => {
          let data = response.data
          commit('setNavigationDrawerSections', data.navigation_drawer_list)
          commit('setClientName', data.client_name)
        })
        .catch((error) => {
          console.error(error)
        })
    },
    changeNavigationDrawerSections ({ commit }, value) {
      commit('setNavigationDrawerSections', value)
    }
  },
  getters: {
  },
  modules: {
    auth, // note that auth is not namespaced
    unnotifications,
    hierarchiesFilterSelectorStore,
    hierarchiesProductStore,
    hierarchiesFiltersList,
    // juno
    ...junoStoreModules,
    // titan
    ...titanStoreModules,
    // dido
    ...didoStoreModules,
    // deimos
    ...deimosStoreModules,
    // juno2
    ...juno2StoreModules
  }
})
